


























import {Component, Emit, Prop, Watch, Vue} from 'vue-property-decorator';
import {Department}      from '@/model/class/department';
import {User}            from '@/model/class/user';
import {PlayerParameter} from '@/model/interface/PlayerParameter';
import BootstrapVue      from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class DeptPlayerSelect extends Vue {
  private deptIndex: number = -1;
  private departments!: Department[];
  private userIndex: number = -1;
  private users: User[] = [];

  @Emit('setPlayerSelected') private setPlayerSelected(player: PlayerParameter): void { return; }

  private get canSelectDept(): boolean {
    return (this.departments.length > 1);
  }

  private get canSelectUser(): boolean {
    return (this.users.length > 1);
  }

  @Watch('deptIndex')
  private onDeptChange(newValue: number, oldValue: number): void {
    this.users = this.departments[newValue].getUsers();
    this.userIndex = -1;
  }

  @Watch('userIndex')
  private onUsrChange(newValue: number, oldValue: number): void {
    if (newValue === -1) {
      // なにもしない？
    } else {
      const usr = this.users[newValue];
      const player: PlayerParameter = {
        id: usr.getId(),
        name: usr.getName(),
        nameShort: usr.getNameShort(),
      };
      this.setPlayerSelected(player);
    }
  }

  private created(): void {
    this.departments = this.$store.getters.getDepartments();
    if (this.departments.length > 0) {
      this.deptIndex = 0;
      this.users = this.departments[0].getUsers();
    }
  }
}
