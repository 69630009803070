





































































































































































import {Component, Vue} from 'vue-property-decorator';
import Header        from '@/components/common/Header.vue';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
  },
})
export default class Score extends Vue {
  private usrType: number = 1;
  private breadItems: Array<{[key: string]: any}> = [
    {text: 'Top', to: { name: 'top' }},
    {text: 'Score', active: true},
  ];

private created(): void {
    try { // ログイン専用
      this._checkLogin().
      then((isLogin: boolean) => {
        this._getResumeData(0); // Gameではないので戻り値は無視
        this.usrType = this.$store.getters.getUsrType();
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      window.console.log(e.message);
      this.$router.push({name: 'error'});
    }
  }

  private startSolo(gameNum: number): void {
    if (this.usrType === this.C_MODE_GUEST) {
      this.$store.dispatch('solo/initGuestAction', gameNum);
      if (gameNum === this.C_NUM_BOWLARD) {
        this.$router.push({name: 'score-bowlard'});
      }
    } else {
      // staff でも memberでもplace-playerへ
      if (gameNum === this.C_NUM_BOWLARD) {
        this.$router.push({name: 'place-player', params: {type: 'bowlard'}});
      }
    }
  }

  private startMatch(gameNum: number): void {
    if (this.usrType === this.C_MODE_GUEST)  {
      const param: {[key: string]: any} = {
        gameType: gameNum,
        player1: {id: 0, name: 'GUEST1', race_to: this.C_RACE_TO[gameNum]},
        player2: {id: 0, name: 'GUEST2', race_to: this.C_RACE_TO[gameNum]},
      };

      param.matchMode = this.C_MODE_GUEST;
      param.matchStatus = this.C_STATUS_CONFIG;
      this.$store.dispatch('match/initGuestAction', param);

      if (gameNum === this.C_NUM_9B) {
        this.$router.push({name: 'score-9ball'});
      } else if (gameNum === this.C_NUM_10B) {
        this.$router.push({name: 'score-10ball'});
      } else if (gameNum === this.C_NUM_14_1) {
        this.$router.push({name: 'score-14one'});
      } else if (gameNum === this.C_NUM_ROTE) {
        this.$router.push({name: 'score-rotation'});
      }
    } else {
      if (gameNum === this.C_NUM_9B) {
        this.$router.push({name: 'place-player', params: {type: '9ball'}});
      } else if (gameNum === this.C_NUM_10B) {
        this.$router.push({name: 'place-player', params: {type: '10ball'}});
      } else if (gameNum === this.C_NUM_14_1) {
        this.$router.push({name: 'place-player', params: {type: '14_1'}});
      } else if (gameNum === this.C_NUM_ROTE) {
        this.$router.push({name: 'place-player', params: {type: 'rotation'}});
      }
    }
  }
}
