
























































































































import {Component, Prop, Emit, Vue} from 'vue-property-decorator';
import {EventStep}     from '@/model/class/EventStep';
import {EventPlayer}     from '@/model/class/EventPlayer';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class EventStepData extends Vue {
  @Prop() private stepNum!: number;
  @Prop() private isMax!: boolean;
  private currentStep!: EventStep;
  private stepName: string = '';
  private stepStatus: number = 0;
  private stepSetNum: number = 0;
  private freePlayers!: {[key: number]: Array<{id: number, name: string, pos: number}>};
  private setPlayers!: {[key: number]: Array<{id: number, name: string, pos: number}>};
  private freeCount: number = 0;
  private setCount: number = 0;
  private importType: number = 0;
  private importStatus: any[] = [];
  private isClose: number = 0;
  private modalImportPlayer: boolean = false;

  private statusOptions: Array<{value: number, text: string}> = [
    { value: 9, text: 'スキップ' },
    { value: 5, text: '勝ち(負け側)' },
    { value: 4, text: '勝ち' },
    { value: 3, text: '負け' },
    { value: 2, text: 'Play中(負け側)' },
    { value: 1, text: 'Play中' },
    { value: 0, text: '待機中' },
  ];

  @Emit('executeStep') private executeStep(stepId: number, param: {[key: string]: any}): void { return; }
  @Emit('destroyStep') private destroyStep(stepId: number, param: {[key: string]: any}): void { return; }
  @Emit('movePlayer') private movePlayer(eventStep: EventStep): void { return; }
  @Emit('orderPlayer') private orderPlayer(eventStep: EventStep, setNum: number): void { return; }
  @Emit('editGame') private editGame(eventStep: EventStep, setNum: number): void { return; }

  private get usrSetLump(): boolean {
    if (this.stepStatus === 1 && this.freeCount > 0 && this.setCount === 0) {
      return true;
    }
    return false;
  }

  private created(): void {
    this.freePlayers = [];
    this.setPlayers = [];
    this.freeCount = 0;
    this.setCount = 0;
    const tmpPlayers = this.$store.getters['event/getPlayers']();
    this.currentStep = this.$store.getters['event/getStep'](this.stepNum);
    const isTNM = (this.currentStep.style.charAt(0) !== 'R');
    const tmpSetNum = parseInt(this.currentStep.style.charAt(1), 10);

    const setLimit = 2 ** tmpSetNum;

    const tmpStruct = (this.currentStep.style.charAt(0) === 'D')
        ? this.C_TNM_ORDER_D[tmpSetNum]
        :  this.C_TNM_ORDER[tmpSetNum];

    // const tmpStruct = this.C_TNM_ORDER[tmpSetNum];
    this.stepName = this.currentStep.name;
    this.stepStatus = this.currentStep.eventStepStatus;
    this.stepSetNum = this.currentStep.setCount;

    for (let i = 0; i < this.currentStep.setCount; i++) {
      this.freePlayers[i] = [];
      this.setPlayers[i] = [];
      if (isTNM) {
        for (let j = 0; j < setLimit; j++) {
          this.setPlayers[i].push({
            id: 0,
            name: '未設定',
            pos: j,
          });
        }
      }
    }

    for (const key in tmpPlayers) {
      if (tmpPlayers[key].eventStepId === this.currentStep.eventStepId) {
        const setNum = tmpPlayers[key].setNumber;
        if (tmpPlayers[key].setOrder < 0) {
          // this.freePlayers[setNum].push(tmpPlayers[key]);
          this.freePlayers[setNum].push({
            id: tmpPlayers[key].eventPlayerId,
            name: tmpPlayers[key].usrNameShort + '(' + tmpPlayers[key].usrClass + ')' + tmpPlayers[key].deptNameShort,
            pos: tmpPlayers[key].setOrder,
          });
          this.freeCount++;
        } else {
          if (setNum === 99 || setNum === 100) {
            continue;
          }

          const tmpItem = {
              id: tmpPlayers[key].eventPlayerId,
              name: tmpPlayers[key].usrNameShort + '(' + tmpPlayers[key].usrClass + ')' + tmpPlayers[key].deptNameShort,
              pos: tmpPlayers[key].setOrder,
          };

          if (isTNM) {
            const tmpOrder = tmpStruct[tmpPlayers[key].setOrder];
            this.setPlayers[setNum][tmpOrder] = tmpItem;
          } else {
            this.setPlayers[setNum].push(tmpItem);
          }
          this.setCount++;
        }
      }
    }

    for (let i = 0; i < this.currentStep.setCount; i++) {
      this.freePlayers[i].sort((a, b) => {
        if (a.pos > b.pos) {
          return 1;
        } else {
          return -1;
        }
      });
      if (!isTNM) {
        this.setPlayers[i].sort((a, b) => {
          if (a.pos > b.pos) {
            return 1;
          } else {
            return -1;
          }
        });
      }
    }
  }

  // importのモーダルOPEN
  private importEntry(): void {
    this.modalImportPlayer = true;
  }

  private importStep1(): void {
    const postParam = {
      command: 'import',
      type: this.importType,
    };
    this.executeStep(this.currentStep.eventStepId, postParam);
    this.modalImportPlayer = false;
  }

  private importStep(): void {
    if (this.importStatus.length === 0) {
      alert('ステータスを選択してください');
      return;
    }
    const postParam = {
      command: 'imports',
      statuses: this.importStatus.join(','),
      is_close: this.isClose,
    };
    this.executeStep(this.currentStep.eventStepId, postParam);
    this.modalImportPlayer = false;
  }

  private moveFreePlayer(): void {
    this.movePlayer(this.currentStep);
  }

  private orderFreePlayer(setNum: number): void {
    this.orderPlayer(this.currentStep, setNum);
  }

  private setLump(isSet: boolean, isInit: boolean, isFix: boolean): void {
    const postParam = {
      command: 'lump',
      is_set: isSet,
      is_init: isInit,
      is_fix: isFix,
    };
    this.executeStep(this.currentStep.eventStepId, postParam);
  }

  private toEditGame(setNum: number): void {
    this.editGame(this.currentStep, setNum);
  }


  private async delStep() {
    // 試合のチェックは不要
    const password = prompt('削除パスワードを入力してください。？');
    if (password === 'yyw') {
      const param = {
        password,
        edit_dt: this.currentStep.editDateTime,
      };
      this.destroyStep(this.currentStep.eventStepId, param);
    }
  }
}
