
























































import {Component, Prop, Vue} from 'vue-property-decorator';
import {EventStep}    from '@/model/class/EventStep';
import EventHeader    from '@/components/event-admin/common/EventHeader.vue';
import libAxios       from '@/mixin/LibAxios';
import BootstrapVue   from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    EventHeader,
  },
})
export default class EARegular extends Vue {
  private isLoading: boolean = true;
  private usrType!: number;
  private regularData: Array<{[key: string]: any}> = [];
  private modalCreateEvent: boolean = false;
  private eventDate: string = '';
  private currentRegularId: number = 0;

  private breadItems: Array<{[key: string]: any}> = [
    { text: 'イベントTop', to: { name: 'event-admin-top' }},
    { text: '定例一覧', active: true},
  ];

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          this.usrType = this.$store.getters.getUsrType();
          this.getRegularList();
        } else {
          this.$router.push({name: 'top'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private getRegularList() {
    const searchParam = {
      usr_id: this.$store.getters.getUsrId(),
    };

    this.isLoading = true;
    const token = this.$store.getters.getToken();
    libAxios.get('regular-data', token, searchParam)
    .then((param: {[key: string]: any}) => {
      this.regularData = param.data;
      this.isLoading = false;
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }

  private createModal(index: number) {
    this.currentRegularId = index;
    this.eventDate = this._formatDate(new Date(), 'Y-m-d');
    this.modalCreateEvent = true;
  }

  private createEvent(index: number) {
    const param: {[key: string]: any} = {
      command: 'addEvent',
      date: this.eventDate ,
    };
    this.executeRegular(param);
    this.modalCreateEvent = false;
  }

  private async executeRegular(param: {[key: string]: any}) {
    const token = this.$store.getters.getToken();
    return await libAxios.post('regular-data/' + this.currentRegularId, token, param)
    .then((data: {[key: string]: any}) => {
      // 正常なら数値がもどる
      this.$router.push({
        name: 'event-admin-event-detail',
        params: { id : String(data) },
      });
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }
}
