import { render, staticRenderFns } from "./DartsNamePlate.vue?vue&type=template&id=4997b774&scoped=true&"
import script from "./DartsNamePlate.vue?vue&type=script&lang=ts&"
export * from "./DartsNamePlate.vue?vue&type=script&lang=ts&"
import style0 from "./DartsNamePlate.vue?vue&type=style&index=0&id=4997b774&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4997b774",
  null
  
)

export default component.exports