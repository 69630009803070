










import {Component, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class EventDetail extends Vue {
  @Prop() private currentEvent!: {[key: string]: any};
  private imagePath: string = '';
  private imageCaption: string = '';
  private bodyHtml: string = '';

  private created(): void {
    this.imagePath = this.currentEvent.img_path;
    this.imageCaption = this.currentEvent.img_text;
    this.bodyHtml = this.currentEvent.body;
    // entryでmsg有効の場合
  }
}
