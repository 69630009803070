




























































import {Component, Vue} from 'vue-property-decorator';
import {EventStep}      from '@/model/class/EventStep';
import Header           from '@/components/common/Header.vue';
import RoundRobin       from '@/components/event/table/RoundRobin.vue';
import Double23         from '@/components/event/table/Double23.vue';
import Single10         from '@/components/event/table/Single10.vue';
import Single40         from '@/components/event/table/Single40.vue';
import Game9BallResult  from '@/components/score/match/Game9BallResult.vue';
import Game10BallResult from '@/components/score/match/Game10BallResult.vue';
import Game14OneResult  from '@/components/score/match/Game14OneResult.vue';
import libAxios         from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
    RoundRobin,
    Double23,
    Single10,
    Single40,
    Game9BallResult,
    Game10BallResult,
    Game14OneResult,
  },
})
export default class EventInfo extends Vue {
  private usrType!: number;
  private isLoading: boolean = true;
  private eventData: {[key: string]: any} = {};
  private eventSteps: {[key: number]: EventStep} = {};
  private eventPlayers: {[num: number]: Array<{[key: string]: any}>} = {};
  private eventGames: {[num: number]: Array<{[key: string]: any}>} = {};
  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'top' }},
  ];
  private eventId: string = '';
  private regularId: string = '';
  private regularTitle: string = '';
  private modalShow: boolean = false;
  private gameNum: number = 0;
  private gameParam: {[key: string]: any} = {};
  private noData: boolean = false;

  private created(): void {
    try {
      if (this.$route.path.substr(0, 14) === '/regular-event') {
        this.regularId = this.$route.params.id1;
        this.eventId = this.$route.params.id2;
        this.regularTitle = this.$route.params.str;
        this.breadItems.push({ text: '定例Event一覧', to: {name: 'regular-event'}});
        this.breadItems.push({
          text: this.regularTitle + '一覧',
          to : { name: 'regular-event-info', params: {id: this.regularId, str: this.regularTitle}},
        });
      } else {
        this.eventId = this.$route.params.id;
        this.breadItems.push({ text: 'Event一覧', to: { name: 'event' }});
      }

      this._checkLogin().
      then((isLogin: boolean) => {
        this._getResumeData(0); // Gameではないので戻り値は無視
        this.usrType = this.$store.getters.getUsrType();
        if (this.usrType === this.C_MODE_GUEST) {
          this.getEventForGuest(this.eventId);
        } else {
          this.getEvent(this.eventId);
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      window.console.log(e.message);
      this.$router.push({name: 'error'});
    }
  }

  private async getEvent(eventId: string) {
    const token = this.$store.getters.getToken();
    return await libAxios.get('event-info/' + eventId, token, {})
    .then((response: {[key: string]: any}) => {
      if (!response) {
        this.isLoading = false;
        this.noData = true;
        return;
      }
      this.$store.dispatch('event/setEventAction', response);
      // イベント名を取得,
      const tmpTitle = this.$store.getters['event/getEventTitle']();
      this.breadItems.push({ text: tmpTitle + '詳細', active: true});
      this.eventSteps = this.$store.getters['event/getSteps']();
      const game = this.$store.getters['event/getGames']();
      this.isLoading = false;
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }

  private async getEventForGuest(eventId: string) {
    return await libAxios.getNoToken('event-list/' + eventId, {})
    .then((response: {[key: string]: any}) => {
      if (!response) {
        this.isLoading = false;
        this.noData = true;
        return;
      }
      this.$store.dispatch('event/setEventAction', response);
      // イベント名を取得,
      const tmpTitle = this.$store.getters['event/getEventTitle']();
      this.breadItems.push({ text: tmpTitle + '詳細', active: true});
      this.eventSteps = this.$store.getters['event/getSteps']();
      const game = this.$store.getters['event/getGames']();
      this.isLoading = false;
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }

  private viewGame(key: string): void {
    const tmpGame = this.$store.getters['event/getGame'](key);
    if (tmpGame === null) {
      return;
    }

    this.gameNum = tmpGame.eventGameType;
    this.gameParam = tmpGame;
    this.modalShow = true;
  }

  private hideDetail() {
    this.modalShow = false;
  }
}
