
























import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    DeptTree,
  },
})
export default class DeptTree extends Vue {
  @Prop() private deptList!: {[key: string]: any};
  @Prop() private levelNum!: number;

  @Emit('roleList') private _roleList(deptId: number): void { return; }
  @Emit('staffList') private _staffList(deptId: number): void { return; }
  @Emit('deptOrder') private _deptOrder(deptId: number): void { return; }

  private created(): void {
    // TODO
  }

  private roleList(deptId: number): void {
    this._roleList(deptId);
  }

  private staffList(deptId: number): void {
    this._staffList(deptId);
  }

  private deptOrder(deptId: number): void {
    this._deptOrder(deptId);
  }
}
