
























































import {Component, Prop, Vue} from 'vue-property-decorator';
import Header        from '@/components/common/Header.vue';
import MessageDetail from '@/components/info/MessageDetail.vue';
import libAxios      from '@/mixin/LibAxios';
import BootstrapVue  from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
    MessageDetail,
  },
})
export default class Message extends Vue {
  private isLoading: boolean = true;
  private usrType!: number;
  private messageList: Array<{[key: string]: any}> = [];
  private currentMsg: {[key: string]: any} = {};
  private currentNum: number = -1;
  private modalMsgInfo: boolean = false;
  private currentTitle: string = '';

   private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'top' }},
    { text: 'メッセージ一覧', active: true},
  ];

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          this.usrType = this.$store.getters.getUsrType();
          this.isLoading = true;
          this.getMessageList();
        } else {
          this.$router.push({name: 'top'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private getMessageList() {
    const searchParam = {};
    const token = this.$store.getters.getToken();
    libAxios.get('message', token, searchParam)
    .then((param: {[key: string]: any}) => {
      this.messageList = param.data;
      this.isLoading = false;
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }

  private showInfo(num: number) {
    this.currentMsg = this.messageList[num];
    this.currentTitle = this.currentMsg.title;
    this.modalMsgInfo = true;
  }
  /*
    'mr_type' 試合なら１
    'ref_id'  試合ならID
    'm_id'    メッセージID
        ,'u_id'      =>['filte
   */
  private async openMsg(num: number) {
    this.currentNum = num;
    const postParam = {
      mr_type: this.messageList[num].ref_type,
      ref_id: this.messageList[num].ref_id,
      m_id: this.messageList[num].m_id,
    };

    const token = this.$store.getters.getToken();
    return await libAxios.post('reply', token, postParam)
    .then((param: {[key: string]: any}) => {
      Object.assign(this.messageList[num], param);
      this.showInfo(num);
    }).catch((e: Error) => {
      this.$router.push({name: 'error'});
    });
  }

  private setRelpy(data: {[key: string]: any}, isRefresh: boolean): void {
    this.modalMsgInfo = false;
    if (isRefresh) {
      this.isLoading = true;
      this.getMessageList();
      return;
    }
    Object.assign(this.messageList[this.currentNum], data);
  }
}
