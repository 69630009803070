



































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {SoloDataParam} from '@/model/interface/SoloDataParam';
import SoloHeader      from '@/components/score/solo/SoloHeader.vue';
import BowlardConfig   from '@/components/score/solo/BowlardConfig.vue';
import BowlardTable    from '@/components/score/solo/BowlardTable.vue';
import BowlardPlay     from '@/components/score/solo/BowlardPlay.vue';

@Component({
  components: {
    SoloHeader,
    BowlardTable,
    BowlardPlay,
    BowlardConfig,
  },
})
export default class GameBowlard extends Vue {
  private isLoading: boolean = true;
  private isSetting: boolean = true;
  private usrType!: number;
  private soloData!: SoloDataParam;
  private currentNum: number = 0; // イベント発生のための変数
  private baseTime: number = 0;
  private baseTimeOrg: number = 0; // 再開時の元
  private place!: string;
  private eventId!: number;

  private get isFin(): boolean {
    const changeParam = this.currentNum;
    const tmpCurrent = this.soloData.boxNum;
    if (tmpCurrent >= 21) {
      return true;
    } else if (tmpCurrent === 20) {
      if (this.soloData.boxChars[18] !== 'X' && this.soloData.boxChars[19] !== '/') {
        return true;
      }
    }
    return false;
  }

  private created(): void {
    try {
      this._checkLogin().
      then((result: boolean) => {
        this.usrType = this.$store.getters.getUsrType();
        this.eventId = this.$store.getters['solo/getEventId']();
        const tmpPlace = this.$store.getters['solo/getPlace']();
        if (tmpPlace.id === 0) {
          this.place = '未指定';
        } else {
          this.place = tmpPlace.name + ' ' + tmpPlace.tableNum + '番台';
        }
        this._created();
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      window.console.log(e.message);
      this.$router.push({name: 'error'});
    }
  }

  private _created(): void {
    const suspend = this._getResumeData(this.C_NUM_BOWLARD);
    // TODO
    if (suspend === '') {
      this.$router.push({name: 'error'}); // SYSTEM ERROR
    }

    const tmpGameId = this.$store.getters['solo/getGameId']();
    if (tmpGameId !== 0) {
      if (tmpGameId !== suspend.gameId) {
        this.$router.push({name: 'error'}); // SYSTEM ERROR
      }
    } else { // suspendからすべてを復活させる
      this.$store.dispatch('solo/resumeAction', suspend);
    }

    const tmpStatus = this.$store.getters['solo/getGameStatus']();
    this.soloData = {
      viewMode: this.C_VIEW_START,
      gameParam: [],
      boxChars: ['*', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      boxScore: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      frameScore: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      boxNum: 0,
      pinNum: 0,
      isFirst: true,
    };

    if (tmpStatus === this.C_STATUS_INIT) {
      const tmpConfig = this._getConfig(this.C_CFG_BOWLARD_PARAM, this.C_NAME_BOWLARD);
      this.$store.dispatch('solo/setConfigAction', tmpConfig);
    } else if (tmpStatus === this.C_STATUS_READY) {
      this.isSetting = false;
    } else if (tmpStatus === this.C_STATUS_PLAY) {
      const dateTime = new Date() ;
      this.baseTime = Math.floor( dateTime.getTime() / 1000 ) ;
      const tmpParam = this.$store.getters['solo/getSoloGameParam']();
      if (tmpParam !== '') {
        const tmpChars = tmpParam.split(',');
        for (const char of tmpChars) {
          this._setScore( parseInt(char, 16), false);
        }
      }
      this.soloData.viewMode = this.C_VIEW_PLAY;
      this.isSetting = false;
    } else {
      // TODO
    }
    this.isLoading = false;
    this.$forceUpdate();
  }

  // STARTこみ
  private startGame(): void {
    this.soloData = {
      viewMode: this.C_VIEW_PLAY,
      gameParam: [],
      boxChars: ['*', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      boxScore: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      frameScore: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      boxNum: 0,
      pinNum: 10,
      isFirst: true,
    };

    // ここで C_STATUS_PLAY
    const dateTime = new Date() ;
    this.baseTime = Math.floor( dateTime.getTime() / 1000 ) ;

    const startParam = {
      startDateTime: this._formatDate(dateTime, ''),
    };
    this.$store.dispatch('solo/startAction', startParam);
    this.isSetting = false;
  }

  private setScore(point: number): void {
    this._setScore(point, true);
  }

  private _setScore(point: number, isVew: boolean): void {
    let stepNum = 1;
    this.soloData.isFirst = !this.soloData.isFirst;
    if (point === 10) {
      if (!this.soloData.isFirst) {
        this.soloData.boxChars[this.soloData.boxNum] = 'X';
        this.soloData.boxScore[this.soloData.boxNum] = 10;

        if (this.soloData.boxNum < 18) {
          stepNum = 2;
          this.soloData.boxChars[this.soloData.boxNum + 1] = '-';
          this.soloData.boxScore[this.soloData.boxNum + 1] = 0;
        }
        this.soloData.isFirst = true;
      } else {
        this.soloData.boxChars[this.soloData.boxNum] = '/';
        this.soloData.boxScore[this.soloData.boxNum] = this.soloData.pinNum;
      }
    } else if (point === -1) {
      this.soloData.boxChars[this.soloData.boxNum] = 'G';
      this.soloData.boxScore[this.soloData.boxNum] = 0;
    } else {
      this.soloData.boxChars[this.soloData.boxNum] = point.toString();
      this.soloData.boxScore[this.soloData.boxNum] = point;
      this.soloData.pinNum -= point;
    }

    if (this.soloData.isFirst) {
      this.soloData.pinNum = 10;
    }

    this.calcScore();

    if (this.soloData.boxNum === 19) {
      if (this.soloData.boxChars[18] !== 'X' && this.soloData.boxChars[19] !== '/') {
        stepNum = 2;
      }
    }

    this.soloData.boxNum += stepNum;
    if (this.soloData.boxNum < 21) {
      this.soloData.boxChars[this.soloData.boxNum] = '*';
    }

    this.currentNum++;
    this.soloData.gameParam.push(point.toString(16));
    if (isVew) {
      const dateTime = new Date() ;
      const nowDate = Math.floor( dateTime.getTime() / 1000 ) ;

      const saveParam: {[key: string]: any} = {
        endDateTime: this._formatDate(dateTime, ''),
        playTimeSpan: nowDate - this.baseTime,
        param: this.soloData.gameParam.join(','),
      };
      this.$store.dispatch('solo/saveAction', saveParam);
    }
    this.$forceUpdate();
  }

  private calcScore(): void {
    let frameNum = 0;
    for (let i = 0; i <= 9; i++) {
      frameNum = i * 2;

      if (i !== 0) {
        this.soloData.frameScore[i] = this.soloData.frameScore[i - 1];
      } else {
        this.soloData.frameScore[i] = 0;
      }
      this.soloData.frameScore[i] += this.soloData.boxScore[frameNum];
      this.soloData.frameScore[i] += this.soloData.boxScore[frameNum + 1];

      if (i === 9) {
        this.soloData.frameScore[i] += this.soloData.boxScore[frameNum + 2];
      }

      if (this.soloData.boxChars[frameNum - 1] === '/') {
        this.soloData.frameScore[i]     += this.soloData.boxScore[frameNum];
        this.soloData.frameScore[i - 1] += this.soloData.boxScore[frameNum];
      } else if (this.soloData.boxChars[frameNum - 2] === 'X') {
        this.soloData.frameScore[i] += this.soloData.boxScore[frameNum];
        this.soloData.frameScore[i] += this.soloData.boxScore[frameNum + 1];
        this.soloData.frameScore[i - 1] += this.soloData.boxScore[frameNum];
        this.soloData.frameScore[i - 1] += this.soloData.boxScore[frameNum + 1];
        if ((i > 1) && (this.soloData.boxChars[frameNum - 4] === 'X')) {
          this.soloData.frameScore[i]     += this.soloData.boxScore[frameNum];
          this.soloData.frameScore[i - 1] += this.soloData.boxScore[frameNum];
          this.soloData.frameScore[i - 2] += this.soloData.boxScore[frameNum];
        }
      }
    }
    this.$store.dispatch('solo/setScoreAction', this.soloData.frameScore[9]);
  }

  private undo(): void {
    if (this.soloData.boxNum === 0) {
      return; // 念のため
    }
    if (this.soloData.boxNum === 21) {
      if (this.soloData.boxChars[20] === '') {
        this.soloData.boxNum = 20;
      }
    } else {
      this.soloData.boxChars[this.soloData.boxNum] = '';
      this.soloData.boxScore[this.soloData.boxNum] = 0;
    }

    if (this.soloData.boxChars[this.soloData.boxNum - 1] === '-') {
      this.soloData.boxChars[this.soloData.boxNum - 2] = '*';
      this.soloData.boxScore[this.soloData.boxNum - 2] = 0;
      this.soloData.boxChars[this.soloData.boxNum - 1] = '';
      this.soloData.boxScore[this.soloData.boxNum - 1] = 0;
      this.soloData.boxNum -= 2;
    } else {
      this.soloData.boxChars[this.soloData.boxNum - 1] = '*';
      this.soloData.boxScore[this.soloData.boxNum - 1] = 0;
      this.soloData.boxNum -= 1;
    }

    if ((this.soloData.boxNum % 2) === 0) {
      this.soloData.isFirst = true;
      this.soloData.pinNum = 10;
    } else {
      if (this.soloData.boxNum === 19 && this.soloData.boxScore[18] === 10) {
        this.soloData.isFirst = true;
        this.soloData.pinNum = 10;
      } else {
        this.soloData.isFirst = false;
        this.soloData.pinNum = 10 - this.soloData.boxScore[this.soloData.boxNum - 1];
      }
    }
    this.currentNum--;
    this.soloData.gameParam.pop();
    this.calcScore();
    this.$forceUpdate();
  }

  private gameFin(isFin: boolean): void {
    this.isLoading = true;
    this.isSetting = true;
    try {
      if (this.usrType === this.C_MODE_GUEST) {// ゲストの場合はデータ破棄
        if (isFin) {
          this.$store.dispatch('solo/destroyAction');
          this.$router.push({name: 'score'});
        } else {
          this.$store.dispatch('solo/retryGuestAction');
          this._created();
        }
      } else {
        this.$store.dispatch('solo/finAction', isFin)
        .then(() => {
          if (isFin) {
            if (this.usrType === this.C_MODE_MEMBER) {
              this.$router.push({name: 'score-solo-list', params: {type: this.C_NAME_BOWLARD}});
            } else {
              this.$router.push({name: 'score'});
            }
          } else {
            this.$store.dispatch('solo/retryAction')
            .then(() => {
              this._created();
            }).catch((e) => {
              throw new Error(e.message);
            });
          }
        }).catch((e) => {
          throw new Error(e.message);
        });
      }
    } catch (e) {
      window.console.log(e.message);
      this.soloData.viewMode = this.C_VIEW_ERROR;
      this.isLoading = true;
    }
  }

  private destroy(): void {
    if (window.confirm('ここまでのデータを破棄しますか？')) {
      this.$store.dispatch('solo/destroyAction');
      this.$router.push({name: 'score'});
    }
  }
}
