



























































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import EventHeader  from '@/components/event-admin/common/EventHeader.vue';
import libAxios     from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    EventHeader,
  },
})
export default class EAEvent extends Vue {
  private isLoading: boolean = true;
  private usrType!: number;
  private eventList: any[] = [];

  private searchParam: {[key: string]: any} = {};
  private currentPage: number = 0;
  private rows!: number;
  private perPage: number = 10;

  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'event-admin-top' }},
    { text: 'イベント一覧', active: true},
  ];

  @Watch('currentPage')
  private onTextChanged(newNum: number, oldNum: number) {
    this.searchParam.page_num = newNum;
    this.searchParam.page_limit = 10;
    this.searchParam.order_column = 'e_id';
    this.searchParam.order_option = 'DESC';
    this.$store.dispatch('searchEventAction', this.searchParam);
    this.getEventList();
  }

  private created(): void {
    try {
      let pageNum = 1;
      const saveParam = this.$store.getters.getSearchEvent();
      if (saveParam) {
        this.searchParam = JSON.parse(saveParam);
      }
      if (this.searchParam.page_num) {
        pageNum = this.searchParam.page_num;
      }

      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          this.usrType = this.$store.getters.getUsrType();
          this.currentPage = pageNum;
        } else {
          this.$router.push({name: 'top'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private async getEventList() {
    const token = this.$store.getters.getToken();
    return await libAxios.get('event-data', token, this.searchParam)
    .then((param: {[key: string]: any}) => {
      this.rows = param.cnt;
      this.eventList = param.data;
      this.isLoading = false;
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }

  private async chcekEvent(eventId: number, eventEditDt: string) {
    if (!confirm('ステータス変更しますか？')) {
      return;
    }
    const postParam = {
      command: 'check',
      edit_dt: eventEditDt,
    };
    this.isLoading = true;
    const token = this.$store.getters.getToken();

    return await libAxios.post('event-data/' + eventId, token, postParam)
    .then((data: {[key: string]: any}) => {
      alert('更新しました');
      this.getEventList();
    }).catch((e: Error) => {
      // throw new Error(e.message);
    });
  }
}
