












































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {EventStep}    from '@/model/class/EventStep';
import EventHeader    from '@/components/event-admin/common/EventHeader.vue';
import Game9BallConfig    from '@/components/score/match/Game9BallConfig.vue';
import Game10BallConfig   from '@/components/score/match/Game10BallConfig.vue';
import Game14OneConfig    from '@/components/score/match/Game14OneConfig.vue';
import GameRotationConfig from '@/components/score/match/GameRotationConfig.vue';
import MatchConfig        from '@/components/score/match/MatchConfig.vue';
import libAxios           from '@/mixin/LibAxios';
import BootstrapVue       from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    EventHeader,
    Game9BallConfig,
    Game10BallConfig,
    Game14OneConfig,
    GameRotationConfig,
    MatchConfig,
  },
})
export default class EAConfigDetail extends Vue {
  private isLoading: boolean = true;
  private configId: number = 0;
  private gameTypeVal = [
    {text: '9 Ball', value: 1},
    {text: '10 Ball', value: 2},
    {text: '14-1', value: 3},
    {text: 'Rotation', value: 4},
  ];
  private viewStep: number = 0;
  private gameType: number = 0;
  private configTitle: string = '';
  private gameMode: number[] = [];
  private raceTo: {[key: string]: number} = {
    P: 0, a: 0, A: 0, b: 0, B: 0, C: 0, D: 0,
    LP: 0, La: 0, LA: 0, Lb: 0, LB: 0, LC: 0, LD: 0,
  };
  private isOpen: boolean = false;
  private editDateTime: string = '';
  private connection: boolean = false;
  private usrId: number = 0;
  private createId: number = 0;

  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Event Admin', to: { name: 'event-admin-top' }},
    { text: '試合設定一覧',  to: { name: 'event-admin-config-list'}},
  ];

  private get canSend(): boolean {
    return this.createId === 0 || this.usrId === this.createId || this.gameMode.some((val) => val === 2);
  }

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          this.usrId = this.$store.getters.getUsrId();
          if (this.$route.params.id) {
            this.configId = parseInt(this.$route.params.id, 10);
            this.viewStep = 2;
            this.getConfig();
          } else {
            this.viewStep = 1;
            this.isLoading = false;
          }
        } else {
          this.$router.push({name: 'error'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private getConfig() {
    const token = this.$store.getters.getToken();
    libAxios.get('event-config/' + this.configId, token, {})
    .then((response) => {
      this.gameType = response.ec_type;
      this.configTitle = response.ec_title;
      this.breadItems.push({ text: this.configTitle + '詳細', active: true});
      const tmpMode = response.ec_mode;
      this.gameMode = [];
      for (let i = 1; i <= 3; i++) {
        if (this._bitCalc(tmpMode, i)) {
          this.gameMode.push(2 ** (i - 1));
        }
      }

      this.isOpen = (response.ec_status === this.C_EVENT_OPEN);

      const format = JSON.parse(response.ec_format);

      if (format.raceTo) {
        this.raceTo = format.raceTo;
      }

      if (format.config) {
        this.$store.dispatch('match/setConfigAction', format.config);
      } else {
        this.setDefault();
      }

      this.createId = response.ec_u_id;
      this.editDateTime = response.edit_dt;
      this.isLoading = false;
      return;
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }

  private setDefault() {
    let tmpConfig = {};
    if (this.gameType === this.C_NUM_9B) {
      tmpConfig = Object.assign(this.C_CFG_9B_PARAM, this.C_CFG_MATCH_COMMON);
    } else if (this.gameType === this.C_NUM_10B) {
      tmpConfig = Object.assign(this.C_CFG_10B_PARAM, this.C_CFG_MATCH_COMMON);
    } else if (this.gameType === this.C_NUM_14_1) {
      tmpConfig = Object.assign(this.C_CFG_14_1_PARAM, this.C_CFG_MATCH_COMMON);
    } else if (this.gameType === this.C_NUM_ROTE) {
      tmpConfig = Object.assign(this.C_CFG_ROTE_PARAM, this.C_CFG_MATCH_COMMON);
    }
    this.$store.dispatch('match/setConfigAction', tmpConfig);
  }

  private next() {
    this.setDefault();
    this.viewStep = 2;
  }

  private save() {
    const tmpStatus = (this.isOpen) ? this.C_EVENT_OPEN : this.C_EVENT_DRAFT;
    let tmpMode = 0;
    if (this.gameMode.length !== 0) {
      tmpMode = this.gameMode.reduce( (a, b) => a + b);
    }
    const tmpParam = {
      raceTo: this.raceTo,
      config: this.$store.getters['match/getConfig'](),
    };

    const postParam: {[key: string]: any} = {
      ec_status: tmpStatus,
      ec_mode: tmpMode,
      ec_title: this.configTitle,
      ec_format: JSON.stringify(tmpParam),
    };

    if (this.configId === 0) {
      postParam.is_new = true;
      postParam.ec_type = this.gameType;
      postParam.ec_u_id = this.$store.getters.getUsrId();
      this.storeConfig(postParam);
    } else {
      postParam.is_new = false;
      postParam.edit_dt = this.editDateTime;
      this.updateConfig(postParam);
    }
  }

  private async storeConfig(param: {[key: string]: any}) {
    const token = this.$store.getters.getToken();
    return await libAxios.post('event-config', token, param)
    .then((data: {[key: string]: any}) => {
      this.configId = data.ec_id;
      alert('作成しました');
      this.isLoading = false;
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }

  private async updateConfig(param: {[key: string]: any}) {
    this.connection = true;
    const token = this.$store.getters.getToken();
    return await libAxios.put('event-config/' + this.configId, token, param)
    .then((data: {[key: string]: any}) => {
      alert('更新しました');
      this.connection = false;
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }
}
