




































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {EventStep}    from '@/model/class/EventStep';
import EventHeader    from '@/components/event-admin/common/EventHeader.vue';
import EventEntry     from '@/components/event-admin/EventEntry.vue';
import EventStep0Data from '@/components/event-admin/EventStep0Data.vue';
import EventStepData  from '@/components/event-admin/EventStepData.vue';
import EventSetMove   from '@/components/event-admin/EventSetMove.vue';
import EventSetOrder  from '@/components/event-admin/EventSetOrder.vue';
import EventGameEdit  from '@/components/event-admin/EventGameEdit.vue';
import EventStepEdit  from '@/components/event-admin/EventStepEdit.vue';
import libAxios       from '@/mixin/LibAxios';
import BootstrapVue   from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    EventHeader,
    EventEntry,
    EventStep0Data,
    EventStepData,
    EventSetMove,
    EventSetOrder,
    EventGameEdit,
    EventStepEdit,
  },
})
export default class EAEventContent extends Vue {
  private isLoading: boolean  = true;
  private eventTitle: string  = '';
  private eventStatus: number = 0; // 0一覧
  private viewMode: number = 0; // 0一覧
  private editSetNum: number = -1;
  private modalAddStep: boolean = false;
  private maxStepNum: number = 1; // 最大step数
  private currentStep!: EventStep;
  private currentStepNum!: number;
  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'event-admin-top' }},
    { text: 'イベント一覧', to: { name: 'event-admin-event' }},
  ];

  private get isCleanupEvent(): boolean {
    this.eventStatus = this.$store.getters['event/getEventStatus']();
    if (this.eventStatus <= this.C_EVENT_HOLD) {
      // 最後のSTEPに最終フラグがたっているか？
      const isLast = this.$store.getters['event/getStepIsFin'](this.maxStepNum - 1);
      if (isLast) {
        // 最後のSTEPが完了か？
        const lastStatus = this.$store.getters['event/getStepStatus'](this.maxStepNum - 1);
        if (lastStatus >= this.C_STEP_END) {
          return true;
        }
      }
    }
    return false;
  }

  private get isOpenEvent(): boolean {
    this.eventStatus = this.$store.getters['event/getEventStatus']();
    return (this.eventStatus < this.C_EVENT_HOLD);
  }

  private get isViewEntry(): boolean {
    return (this.$store.getters['event/getStepStatus'](0) < this.C_STEP_END);
  }

  private get isViewStep0(): boolean {
    const stepLen = this.$store.getters['event/getStepsLength']();
    if (stepLen === 1) {
      return (this.$store.getters['event/getStepStatus'](0) >= this.C_STEP0_FETCH);
    } else if (stepLen > 1) {
      return (this.$store.getters['event/getStepStatus'](1) < this.C_STEP_CLOSE);
    }
    return false;
  }

  private get isViewNext(): boolean {
    if (this.maxStepNum === 1) {
      return (this.$store.getters['event/getStepStatus'](0) >= this.C_STEP_END);
    } else if (this.maxStepNum > 1) {
      return !this.$store.getters['event/getStepIsFin'](this.maxStepNum - 1);
    }
    return false;
  }

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          const eventId = this.$route.params.id;
          this.$store.dispatch('event/getEventAction', eventId)
          .then(() => {
            this.viewEventSteps();
            this.breadItems.push({ text: this.eventTitle + '試合詳細', active: true});
            this.isLoading = false;
          }).catch((e) => {
            window.console.log(e.message);
          });
        } else {
          this.$router.push({name: 'error'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private viewEventSteps(): void {
    this.eventTitle = this.$store.getters['event/getEventTitle']();
    this.eventStatus = this.$store.getters['event/getEventStatus']();
    this.maxStepNum = this.$store.getters['event/getStepsLength']();
  }

  private openEvent(): void {
    const postParam = {
      command: 'open',
      edit_dt: this.$store.getters['event/getEventEditDateTime'](),
    };
    this.executeEvent(postParam);
  }

  private cleanupEvent(): void {
    const postParam = {
      command: 'clean',
      edit_dt: this.$store.getters['event/getEventEditDateTime'](),
    };
    this.executeEvent(postParam);
  }

  private openEditStep(): void {
    this.maxStepNum = this.$store.getters['event/getStepsLength']();
    this.modalAddStep = true;
  }

  private addStep(): void {
    // 初期化して表示
    // this.modalAddStep = true;
  }

  private cancel(): void {
    this.viewMode = 0;
  }

  // 表示が変わるのでここでやる。
  private executeEvent(param: {[key: string]: any}): void {
    this.isLoading = true;
    this.$store.dispatch('event/executeEventAction', param)
    .then(() => {
      this.isLoading = false;
    }).catch((e) => {
      window.console.log(e);
    });
  }

  // STEP
  private storeStep(param: {[key: string]: any}): void {
    this.isLoading = true;
    this.$store.dispatch('event/storeStepAction', param)
    .then(() => {
      this.viewEventSteps();
      this.modalAddStep = false;
      this.isLoading = false;
    }).catch((e) => {
      window.console.log(e);
    });
  }

  private executeStep(stepId: number, param: {[key: string]: any}): void {
    this.isLoading = true;
    this.$store.dispatch('event/executeStepAction', {stepId, param})
    .then(() => {
      this.isLoading = false;
    }).catch((e) => {
      window.console.log(e);
    });
  }

  private async destroyStep(stepId: number, param: {[key: string]: any}) {
    this.isLoading = true;
    this.$store.dispatch('event/destroyStepAction', {stepId, param})
    .then(() => {
      this.viewEventSteps();
      this.isLoading = false;
    }).catch((e) => {
      window.console.log(e);
    });
  }

  // 表示が変わるのでここでやる。
  private storePlayer(stepId: number, param: {[key: string]: any}): void {
    this.isLoading = true;
    this.$store.dispatch('event/storePlayerAction', {stepId, param})
    .then(() => {
      this.isLoading = false;
    }).catch((e) => {
      window.console.log(e);
    });
  }

  // 表示が変わるのでここでやる。
  private updatePlayer(stepId: number, playerID: number, param: {[key: string]: any}): void {
    this.isLoading = true;
    this.$store.dispatch('event/updatePlayerAction', {stepId, playerID, param})
    .then(() => {
      this.isLoading = false;
    }).catch((e) => {
      window.console.log(e);
    });
  }

  // 表示が変わるのでここでやる。
  private movePlayer(eventStep: EventStep) {
    this.currentStep = eventStep;
    this.currentStepNum = eventStep.eventStepNum;
    this.viewMode = 1;
  }

  // 表示が変わるのでここでやる。
  private orderPlayer(eventStep: EventStep, setNum: number) {
    this.currentStep = eventStep;
    this.editSetNum = setNum;
    this.viewMode = 2;
  }

   // 表示が変わるのでここでやる。
  private editGame(eventStep: EventStep, setNum: number) {
    this.currentStep = eventStep;
    this.editSetNum = setNum;
    this.viewMode = 3;
  }
}
