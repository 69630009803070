



















































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class Game10BallBall extends Vue {
  @Prop() private workBalls!: number[];
  @Prop() private initBalls!: number[];

  @Emit('update:workBalls') private updateBalls(nums: number[]): void { return; }

  get _workBalls(): number[] {
    return this.workBalls;
  }

  set _workBalls(value: number[]) {
    this.updateBalls(value);
  }

  // 初期化
  private created(): void {
   // TODO
  }

  private buttonColor(num: number): string {
    if (this.initBalls.indexOf(num) !== -1) {
      return 'secondary';
    } else {
      return 'outline-info';
    }
  }

  private isDisabled(num: number): boolean {
     if (this.initBalls.indexOf(num) !== -1) {
       return true;
    } else {
       return false;
    }
  }
}
