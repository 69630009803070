










































































import {Component, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class PointConfig extends Vue {
  private actionVal = [
    {text: 'しない', value: 0},
    {text: 'する', value: 1},
  ];
  private simpleVal = [
    {text: 'なし', value: 0},
    {text: 'あり', value: 1},
  ];
  private inputMethodVal = [
    {text: '得点選択', value: 1},
    {text: 'ボール選択', value: 2},
  ];

  private config: {[key: string]: number} = this.$store.getters['multi/getConfig']();

  private get isDisabled(): boolean {
    return (this.config.isEdit === 0);
  }

  private get _UseDouble(): number {
    return this.config.useDouble;
  }

  private set _UseDouble(value: number) {
    this.$store.dispatch('multi/setConfigParamAction', {key: 'useDouble', val: value});
  }

  private get _NextRandom(): number {
    return this.config.nextRandom;
  }

  private set _NextRandom(value: number) {
    this.$store.dispatch('multi/setConfigParamAction', {key: 'nextRandom', val: value});
  }

  private get _IsOpen(): number {
    return this.config.isOpen;
  }

  private set _IsOpen(value: number) {
    this.$store.dispatch('multi/setConfigParamAction', {key: 'isOpen', val: value});
  }

  private get _InputMethod(): number {
    return this.config.inputMethod;
  }

  private set _InputMethod(value: number) {
    this.$store.dispatch('multi/setConfigParamAction', {key: 'inputMethod', val: value});
  }

  private get _LimitInning(): number {
    return this.config.limitInning;
  }

  private set _LimitInning(value: number) {
    this.$store.dispatch('multi/setConfigParamAction', {key: 'limitInning', val: value});
  }
}
