






































import {Component, Prop, Vue} from 'vue-property-decorator';
import {FreePointParam}   from '@/model/interface/FreePointParam';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class FreePointTable extends Vue {
  @Prop() private playerData!: FreePointParam[];
  @Prop() private currentNum!: number;
  private playerLen!: number;

  private get playerCountClass(): string {
    if (this.playerLen > 5) {
      return 'player-count6';
    } else {
      return 'player-count' + this.playerLen.toString();
    }
  }

  private getScore(index: number, inning: number): number|string {
    if ((inning - 1) === this.currentNum) {
      return '-';
    } else if ((inning - 1) < this.currentNum) {
      return this.playerData[index].scores[inning - 1];
    } else {
      return '';
    }
  }

  private getPoint(index: number, inning: number): number|string {
    if ((inning - 1) === this.currentNum) {
      return '-';
    } else if ((inning - 1) < this.currentNum) {
      const tmpTimes = this.playerData[index].times[inning - 1];
      let tmpPoint = `${this.playerData[index].points[inning - 1]}`;
      if (tmpTimes === 2) {
        tmpPoint += 'x2';
      } else if (tmpTimes === 3) {
        tmpPoint += 'x3';
      } else if (tmpTimes === -1) {
        tmpPoint = '-' + tmpPoint;
      }
      return tmpPoint;
    } else {
      return '';
    }
  }

  private getBGColoer(index: number, inning: number): string {
    if ((inning - 1) < this.currentNum) {
      if (this.playerData[index].times[inning - 1] === 1) {
        return 'point-x1';
      } else if (this.playerData[index].times[inning - 1] === 2) {
        return 'point-x2';
      } else if (this.playerData[index].times[inning - 1] === 3) {
        return 'point-x3';
      } else if (this.playerData[index].times[inning - 1] < 0) {
        return 'point-minus';
      }
    }
    return '';
  }

  private created(): void {
    this.playerLen = this.playerData.length;
  }
}
