





import {Component, Vue} from 'vue-property-decorator';
import CommonEvent      from '@/components/common/Event.vue';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    CommonEvent,
  },
})
export default class EventListForR extends Vue {

}
