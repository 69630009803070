







































import {Component, Emit, Prop, Vue}  from 'vue-property-decorator';
import {PlaceParam}      from '@/model/interface/PlaceParam';
import {PlayerParameter} from '@/model/interface/PlayerParameter';
import Header            from '@/components/common/Header.vue';
import PlaceTablePanel   from '@/components/score/common/PlaceTablePanel.vue';
import DeptPlayerPanel   from '@/components/score/common/DeptPlayerPanel.vue';
import BootstrapVue      from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
    PlaceTablePanel,
    DeptPlayerPanel,
  },
})
export default class PlacePlayer extends Vue {
  private isLoading: boolean = true;
  private usrType!: number;
  private gameName!: string;
  private gameNum!: number;
  private setType!: number; // 1 solo 2 match-member 3 match-staff
  private nextUrl!: {name: string};
  private usr1st: boolean = false;
  private usr2nd: boolean = false;

  private placeTab: number = 0;
  private place: PlaceParam = {id: 0, name: '未選択', tableNum: 0};

  private player1Tab: number = 0;
  private Player1Param: PlayerParameter = {id: 0, name: '', nameShort: ''};
  private player2Tab: number = 0;
  private Player2Param: PlayerParameter = {id: 0, name: '', nameShort: ''};

  private setPlace(placeTab: number, place: PlaceParam): void {
    this.placeTab = placeTab;
    this.place = place;
  }

  private setPlayer1(playerTab: number, player: PlayerParameter): void {
    this.player1Tab = playerTab;
    this.Player1Param = player;
  }

  private setPlayer2(playerTab: number, player: PlayerParameter): void {
    this.player2Tab = playerTab;
    this.Player2Param = player;
  }

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          this._getResumeData(0); // Gameではないので戻り値は無視
          this.usrType = this.$store.getters.getUsrType();
          this.gameName = this.$route.params.type;

          if (this.gameName === this.C_NAME_BOWLARD) {
            this.gameNum = this.C_NUM_BOWLARD;
            this.setType = 1;
            this.nextUrl = {name: 'score-bowlard'};
          } else if (this.gameName === this.C_NAME_9B) {
            this.gameNum = this.C_NUM_9B;
            this.setType = 2;
            this.nextUrl = {name: 'score-9ball'};
          } else if (this.gameName === this.C_NAME_10B) {
            this.gameNum = this.C_NUM_10B;
            this.setType = 2;
            this.nextUrl = {name: 'score-10ball'};
          } else if (this.gameName === this.C_NAME_14_1) {
            this.gameNum = this.C_NUM_14_1;
            this.setType = 2;
            this.nextUrl = {name: 'score-14one'};
          } else if (this.gameName === this.C_NAME_ROTE) {
            this.gameNum = this.C_NUM_ROTE;
            this.setType = 2;
            this.nextUrl = {name: 'score-rotation'};
          }

          if (this.setType === 1) { // this.usrTypeは3しか来ない
            if (this.usrType === this.C_MODE_MEMBER) {
              this.usr1st = false;
            } else {
              this.usr1st = true;
            }
            this.usr2nd = false;
          } else {
            if (this.usrType === this.C_MODE_MEMBER) {
              this.usr1st = false;
              this.usr2nd = true;
            } else {
              this.setType = 3;
              this.usr1st = true;
              this.usr2nd = true;
            }
          }
          this.isLoading = false;
        } else {
          this.$router.push({name: 'top'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      window.console.log(e.message);
      this.$router.push({name: 'error'});
    }
  }

  private next(): void {
    let player1Id = 0;
    let player2Id = 0;
    let player1Name = 'Guest';
    let player2Name = 'Guest';
    if (this.usr1st) {
      player1Id = this.Player1Param.id;
      player1Name = this.Player1Param.name;
    } else {
      player1Id = this.$store.getters.getUsrId();
      player1Name = this.$store.getters.getUsrName();
    }
    if (this.usr2nd) {
      player2Id = this.Player2Param.id;
      player2Name = this.Player2Param.name;
    }

    const isChecked: number = (this.usrType === this.C_MODE_MEMBER) ? 1 : 0;
    if (this.setType === 1) { // SOLO
      if (player1Id === 0) {
        alert('Select Player1');
        return;
      }

      const gameParam = {
        gameType: this.gameNum,
        player: {id: player1Id, name: player1Name},
        place: this.place,
        checked: isChecked,
      };

      this.$store.dispatch('solo/initAction', gameParam)
      .then(() => {
        this.$router.push(this.nextUrl);
      }).catch((e) => {
        window.console.log(e);
      });
    } else { // MATCH
      if (this.setType === 3 && player1Id === 0) {
        alert('Select Player1');
        return;
      } else if (this.player1Tab === 1 && player1Id === 0) {
        alert('Search Player1');
        return;
      }

      if (this.player2Tab === 0 && player2Id === 0) {
        alert('Select Player2');
        return;
      } else if (this.player2Tab === 1 && player2Id === 0) {
        alert('Search Player2');
        return;
      } else if (this.player2Tab === 3) {
        player2Id = player1Id;
        player2Name = player1Name + '(G)';
      }

      const tmpPalyer1 = {id: player1Id, name: player1Name, race_to: this.C_RACE_TO[this.gameNum]};
      const tmpPalyer2 = {id: player2Id, name: player2Name, race_to: this.C_RACE_TO[this.gameNum]};
      const gameParam = {
        gameType: this.gameNum,
        player1: tmpPalyer1,
        player2: tmpPalyer2,
        place: this.place,
        checked: isChecked,
      };
      this.$store.dispatch('match/initAction', gameParam)
      .then(() => {
        this.$router.push(this.nextUrl);
      }).catch((e) => {
        window.console.log(e);
      });
    }
  }
}
