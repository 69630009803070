














































































import {Component, Prop, Emit, Vue} from 'vue-property-decorator';
import {EventPlayer}     from '@/model/class/EventPlayer';
import {EventConfig}    from '@/model/class/EventConfig';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class EventStepEdit extends Vue {
  @Prop() private maxStepNum!: number;
  private stepName: string = '';
  private setCount: number = 1;
  private setLimit: number = 5;
  private stepType: number = 0;
  private tnmType: string = '';
  private tnmOptions: Array<{value: string, text: string}> = [
    { value: 'S10', text: '2人シングル(1残)' },
    { value: 'S20', text: '4人シングル(1残)' },
    { value: 'S30', text: '8人シングル(1残)' },
    { value: 'S40', text: '16人シングル(1残)' },
    { value: 'S50', text: '32人シングル(1残)' },
    { value: 'D23', text: '4人1回戦ダブル(3残)' },
    { value: 'D20', text: '4人ダブル(1残)' },
    { value: 'D21', text: '4人ダブル(2残)' },
    { value: 'D30', text: '8人ダブル(1残)' },
    { value: 'D31', text: '8人ダブル(2残)' },
    { value: 'D32', text: '8人ダブル(4残)' },
    { value: 'D40', text: '16人ダブル(1残)' },
    { value: 'D41', text: '16人ダブル(2残)' },
    { value: 'D42', text: '16人ダブル(4残)' },
    { value: 'D43', text: '16人ダブル(8残)' },
    { value: 'D50', text: '32人ダブル(1残)' },
  ];
  private openType: number = 0;
  private openOptions: Array<{value: number, text: string}> = [
    { value: 9, text: 'Close' },
    { value: 6, text: 'Player' },
    { value: 2, text: 'Member' },
    { value: 0, text: 'Open' },
  ];
  private cfgType: number = 0;
  private cfgOptions: Array<{value: number, text: string}> = [];
  private configs: {[key: number]: EventConfig} = this.$store.getters['event/getConfigs']();
  private isLast: number = 0;

  @Emit('storeStep') private storeStep(param: {[key: string]: any}): void { return; }

  private get getUseAddBtn(): boolean {
    if (this.stepName === '') {
      return true;
    }
    if (this.stepType === 0) {
      return true;
    } else if (this.stepType === 2 && this.tnmType === '') {
      return true;
    }
    if (this.cfgType === 0) {
      return true;
    }
    return false;
  }

  private created(): void {
    Object.keys(this.configs).map((str: string) => {
      const key = parseInt(str, 10);
      this.cfgOptions.push({ value: key, text: this.configs[key].title});
    });
  }

  private addStep(): void {
    const tmpStyle = (this.stepType === 1) ? 'R00' : this.tnmType;
    const postParam = {
      es_num: this.maxStepNum,
      name: this.stepName,
      set_cnt: this.setCount,
      style: tmpStyle,
      set_lim: this.setLimit,
      format: this.cfgType,
      scorp: this.openType,
      is_final: this.isLast,
    };
    this.storeStep(postParam);
  }
}
