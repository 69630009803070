import Vue from 'vue';
import Router from 'vue-router';
import Top from './views/Top.vue';
import About from './views/About.vue';
import Contact from './views/Contact.vue';
import Error from './views/Error.vue';
import Login from './views/Login.vue';
import Message        from './views/Message.vue';
import PersonalMenu   from './views/PersonalMenu.vue';
import ProfileCreate  from './views/ProfileCreate.vue';
import ProfileEdit    from './views/ProfileEdit.vue';
import Belong         from './views/Belong.vue';
import Password       from './views/Password.vue';
import EntryStart     from './views/EntryStart.vue';
import Score          from './views/Score.vue';
import PlacePlayer    from './views/score/PlacePlayer.vue';
import GameBowlard    from './views/score/GameBowlard.vue';
import Game9Ball      from './views/score/Game9Ball.vue';
import Game10Ball     from './views/score/Game10Ball.vue';
import Game14One      from './views/score/Game14One.vue';
import GameRotation   from './views/score/GameRotation.vue';
import GamePoint      from './views/score/GamePoint.vue';
import GameFreePoint  from './views/score/GameFreePoint.vue';
import ScoreList      from './views/score/ScoreList.vue';
import ScoreSoloList  from './views/score/ScoreSoloList.vue';
import ScoreMultiList from './views/score/ScoreMultiList.vue';
import EventMatch     from './views/score/EventMatch.vue';
import ScoreReport    from './views/score/ScoreReport.vue';
import ScoreSimple    from './views/score/ScoreSimple.vue';
import EventList      from './views/EventList.vue';
import EventInfo      from './views/EventInfo.vue';
import RegularEvent   from './views/RegularEvent.vue';
import EventListForR  from './views/EventListForR.vue';

import EATop          from './views/event-admin/EATop.vue';
import EAEvent        from './views/event-admin/EAEvent.vue';
import EAEventDetail  from './views/event-admin/EAEventDetail.vue';
import EAEventContent from './views/event-admin/EAEventContent.vue';
import EAConfig       from './views/event-admin/EAConfig.vue';
import EAConfigDetail from './views/event-admin/EAConfigDetail.vue';
import EARegular      from './views/event-admin/EARegular.vue';
import EARegularDetail from './views/event-admin/EARegularDetail.vue';
import AdminTop       from './views/admin/AdminTop.vue';
import OrgList        from './views/admin/OrgList.vue';
import OrgCreate      from './views/admin/OrgCreate.vue';
import OrgDetail      from './views/admin/OrgDetail.vue';
import OrgTree        from './views/admin/OrgTree.vue';
import DeptDetail     from './views/admin/DeptDetail.vue';
import DeptCreate     from './views/admin/DeptCreate.vue';
import DeptUsrList    from './views/admin/DeptUsrList.vue';

import Darts          from './views/Darts.vue';
import Darts01        from './views/score/Darts01.vue';
import DartsPlacePlayer from './views/score/DartsPlacePlayer.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes: [
    {
      path: '/',
      name: 'top',
      component: Top,
    },
    {
      path: '/about',
      name: 'about',
      component: About,
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
    },
    {
      path: '/error',
      name: 'error',
      component: Error,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/message',
      name: 'message',
      component: Message,
    },
    {
      path: '/personal-menu',
      name: 'personal-menu',
      component: PersonalMenu,
    },
    {
      path: '/profile',
      name: 'profile',
      component: ProfileEdit,
    },
    {
      path: '/belong',
      name: 'belong',
      component: Belong,
    },
    {
      path: '/password',
      name: 'password',
      component: Password,
    },
    {
      path: '/entry-start',
      name: 'entry-start',
      component: EntryStart,
    },
    {
      path: '/entry/:token/:no',
      name: 'entry-create',
      component: ProfileCreate,
    },
    {
      path: '/score',
      name: 'score',
      component: Score,
    },
    {
      path: '/score/place-player/:type',
      name: 'place-player',
      component: PlacePlayer,
    },
    {
      path: '/score/bowlard',
      name: 'score-bowlard',
      component: GameBowlard,
    },
    {
      path: '/score/9ball',
      name: 'score-9ball',
      component: Game9Ball,
    },
    {
      path: '/score/10ball',
      name: 'score-10ball',
      component: Game10Ball,
    },
    {
      path: '/score/14-1',
      name: 'score-14one',
      component: Game14One,
    },
    {
      path: '/score/rotation',
      name: 'score-rotation',
      component: GameRotation,
    },
    {
      path: '/score/point',
      name: 'score-point',
      component: GamePoint,
    },
    {
      path: '/score/free-point',
      name: 'score-free-point',
      component: GameFreePoint,
    },
    {
      path: '/score-list/:type',
      name: 'score-list',
      component: ScoreList,
    },
    {
      path: '/score-solo-list/:type',
      name: 'score-solo-list',
      component: ScoreSoloList,
    },
    {
      path: '/score-multi-list/:type',
      name: 'score-multi-list',
      component: ScoreMultiList,
    },
    {
      path: '/event-match/:type',
      name: 'event-match',
      component: EventMatch,
    },
    {
      path: '/score/report/:type',
      name: 'score-report',
      component: ScoreReport,
    },
    {
      path: '/score/simple',
      name: 'score-simple',
      component: ScoreSimple,
    },
    {
      path: '/event',
      name: 'event',
      component: EventList,
    },
    {
      path: '/event/:id',
      name: 'event-info',
      component: EventInfo,
    },
    {
      path: '/regular-event',
      name: 'regular-event',
      component: RegularEvent,
    },
    {
      path: '/regular-event/:id/:str',
      name: 'regular-event-info',
      component: EventListForR,
    },
    {
      path: '/regular-event/:id1/event/:id2/:str',
      name: 'regular-event-event-info',
      component: EventInfo,
    },
    {
      path: '/event-admin',
      name: 'event-admin-top',
      component: EATop,
    },
    {
      path: '/event-admin/event',
      name: 'event-admin-event',
      component: EAEvent,
    },
    {
      path: '/event-admin/event/:id',
      name: 'event-admin-event-detail',
      component: EAEventDetail,
    },
    {
      path: '/event-admin/event-content/:id',
      name: 'event-admin-event-content',
      component: EAEventContent,
    },
    {
      path: '/event-admin/config-list',
      name: 'event-admin-config-list',
      component: EAConfig,
    },
    {
      path: '/event-admin/config',
      name: 'event-admin-config-create',
      component: EAConfigDetail,
    },
    {
      path: '/event-admin/config/:id',
      name: 'event-admin-config-detail',
      component: EAConfigDetail,
    },
    {
      path: '/event-admin/regular',
      name: 'event-admin-regular',
      component: EARegular,
    },
    {
      path: '/event-admin/regular/:id',
      name: 'event-admin-regular-detail',
      component: EARegularDetail,
    },
    {
      path: '/admin',
      name: 'admin-top',
      component: AdminTop,
    },
    {
      path: '/admin/org-list',
      name: 'admin-org-list',
      component: OrgList,
    },
    {
      path: '/admin/org',
      name: 'admin-org-create',
      component: OrgCreate,
    },
    {
      path: '/admin/org/:id',
      name: 'admin-org-detail',
      component: OrgDetail,
    },
    {
      path: '/admin/org-tree/:id',
      name: 'admin-org-tree',
      component: OrgTree,
    },
    {
      path: '/admin/dept',
      name: 'admin-dept-create',
      component: DeptCreate,
    },
    {
      path: '/admin/dept/:id',
      name: 'admin-dept-detail',
      component: DeptDetail,
    },
    {
      path: '/admin/dept-usr',
      name: 'admin-dept-usr',
      component: DeptUsrList,
    },

    // ダーツ
    {
      path: '/darts',
      name: 'darts',
      component: Darts,
    },
    {
      path: '/darts/place-player/:type',
      name: 'darts-place-player',
      component: DartsPlacePlayer,
    },
    {
      path: '/darts/zero-one',
      name: 'darts-zero_one',
      component: Darts01,
    },
  ],
});
