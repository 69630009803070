



























































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class OrgParam extends Vue {
  @Prop() private orgParam!: {[key: string]: any};
  @Prop() private errParam!: {[key: string]: any};
  private oKey: string = '';
  private oName: string = '';
  private oPcode1: string = '';
  private oPcode2: string = '';
  private oPref: number = 0;
  private oAddr: string = '';
  private oTel1: string = '';
  private oTel2: string = '';
  private oTel3: string = '';
  private oFax1: string = '';
  private oFax2: string = '';
  private oFax3: string = '';
  private oHp: string = '';
  private oFb: string = '';
  private oScorp: number = 0;
  private btnName: string = '';
  private isNew: boolean = true;

  private errorOKey: boolean = false;
  private errorOName: boolean = false;

  @Emit('saveData') private _saveData(sendParam: {[key: string]: any}): void { return; }

  private get oKeyState(): boolean {
    return (this.oKey.length >= 3);
  }

  private get oNameState(): boolean {
    return (this.oName.length > 0);
  }

  private get oPrefState(): boolean {
    return (this.oPref !== 0);
  }

  private get canSend(): boolean {
    return (this.oKeyState && this.oNameState && this.oPrefState);
  }

  private created(): void {
    if (this.orgParam) {
      this.oKey    = this.orgParam.o_key;
      this.oName   = this.orgParam.o_name;
      const oPcodeBuf = (this.orgParam.o_pcode + '-').split('-');
      this.oPcode1  = oPcodeBuf[0];
      this.oPcode2  = oPcodeBuf[1];
      this.oPref    = this.orgParam.o_pref;
      this.oAddr    = this.orgParam.o_addr;
      const oTelBuf = (this.orgParam.o_tel + '--').split('-');
      this.oTel1    = oTelBuf[0];
      this.oTel2    = oTelBuf[1];
      this.oTel3    = oTelBuf[2];
      const oFaxBuf = (this.orgParam.o_fax + '--').split('-');
      this.oFax1    = oFaxBuf[0];
      this.oFax2    = oFaxBuf[1];
      this.oFax3    = oFaxBuf[2];
      this.oHp     = this.orgParam.o_hp;
      this.oFb     = this.orgParam.o_fb;
      this.oScorp  = this.orgParam.o_scorp;
      this.btnName = '更新';
      this.isNew   = false;
    } else {
      this.btnName = '追加';
    }
  }

  private saveData() {
    const postParam: {[key: string]: any} = {
      o_key:    this.oKey,
      o_name:   this.oName,
      o_pcode:  this.oPcode1 + '-' + this.oPcode2,
      o_pref:   this.oPref,
      o_addr:   this.oAddr,
      o_tel:    this.oTel1 + '-' + this.oTel2 + '-' + this.oTel3,
      o_fax:    this.oFax1 + '-' + this.oFax2 + '-' + this.oFax3,
      o_hp:     this.oHp,
      o_fb:     this.oFb,
      o_scorp:  this.oScorp,
    };
    this._saveData(postParam);
  }
}
