




















































import {Component, Prop, Emit, Vue} from 'vue-property-decorator';
import {EventStep}     from '@/model/class/EventStep';
import {EventGame}     from '@/model/class/EventGame';
// import {EventPlayer}     from '@/model/class/EventPlayer';
import RoundRobinDetail   from '@/components/event-admin/table/RoundRobinDetail.vue';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    RoundRobinDetail,
  },
})
export default class RoundRobin extends Vue {
  @Prop() private eventStep!: EventStep;
  @Prop() private editSetNums!: number[];
  // private stepSetNum: number = 0;
  private players!: {[key: number]: Array<{id: number, name: string, nameShort: string, order: number, pos: number}>};
  private results!: {[key: number]: Array<{id: number, win: number, lose: number, draw: number, pos: number}>};
  // private maxSetNum: number = 0;
  private isAllFin: boolean = true;

  @Emit('editGame') private _EditGame(key: string): void { return; }
  @Emit('executeStep') private executeStep(stepId: number, param: {[key: string]: any}): void { return; }

  private editGame(key: string): void {
    this._EditGame(key);
  }

  private fixStep(): void {
    const postParam = {
      command: 'fix',
    };
    this.executeStep(this.eventStep.eventStepId, postParam);
  }

  private finStep(): void {
    let postData = '';
    for (const i of this.editSetNums) {
      for (const result of this.results[i]) {
        postData += result.id + '_' + result.pos + ',';
      }
    }
    const postParam = {
      command: 'fin',
      ids: postData,
    };
    this.executeStep(this.eventStep.eventStepId, postParam);
  }

  private created(): void {
    const tmpPlayers = this.$store.getters['event/getPlayers']();
    this.players = {};
    this.results = {};
    for (const i of this.editSetNums) {
      this.players[i] = [];
      this.results[i] = [];
    }

    Object.keys(tmpPlayers).map((str: string) => {
      const key = parseInt(str, 10);
      if (tmpPlayers[key].eventStepId === this.eventStep.eventStepId
        && tmpPlayers[key].setOrder >= 0) {
        const setNum = tmpPlayers[key].setNumber;
        this.players[setNum].push({
          id: tmpPlayers[key].eventPlayerId,
          name: tmpPlayers[key].usrName,
          nameShort: tmpPlayers[key].usrNameShort,
          order: tmpPlayers[key].setOrder,
          pos: tmpPlayers[key].setPosition,
        });
      }
    });

    const tmpGames = this.$store.getters['event/getGames']();
    for (const tmpKey in tmpGames) {
      if (tmpGames[tmpKey].eventStepId === this.eventStep.eventStepId) {
        if (tmpGames[tmpKey].eventGameStatus !== this.C_GAME_STATUS_FIX) {
          this.isAllFin = false;
          break;
        }
      }
    }

    if (this.eventStep.eventStepStatus < this.C_STEP_FIX) {
      for (const i of this.editSetNums) {
        this.players[i].sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          } else {
            return -1;
          }
        });
      }
    } else {
      for (const i of this.editSetNums) {
        this.players[i].sort((a, b) => {
          if (a.pos > b.pos) {
            return 1;
          } else {
            return -1;
          }
        });
      }

      // status > 4
      for (const i of this.editSetNums) {
        for (const players of this.players[i]) {
          const j = players.order;
          const tmpResult = {id: players.id, win: 0, lose: 0, draw: 0, pos: players.pos};
          const maxLen = this.players[i].length;
          for (let k = 0; k < maxLen; k++) {
            if (j === k) {
              continue;
            }
            if (j < k) {
              const tmpKey = this.eventStep.eventStepId + '_' + i + '_' + j + '_' + k;
              const tmpGame = this.$store.getters['event/getGame'](tmpKey);
              if (tmpGame.winner === 1) {
                tmpResult.win++;
              } else if (tmpGame.winner === 2) {
                tmpResult.lose++;
              } else {
                tmpResult.draw++;
              }
            } else {
              const tmpKey = this.eventStep.eventStepId + '_' + i + '_' + k + '_' + j;
              const tmpGame = this.$store.getters['event/getGame'](tmpKey);
              if (tmpGame.winner === 1) {
                tmpResult.lose++;
              } else if (tmpGame.winner === 2) {
                tmpResult.win++;
              } else {
                tmpResult.draw++;
              }
            }
          }
          this.results[i].push(tmpResult);
        }
      }
    }
  }
}
