import axios  from 'axios';

const apiUrl = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_VER + '/';
// const apiUrl = 'https://billiard-note.com/api/v1/';

export default {
  postNoToken: async (apiPath: string, postParam: {[key: string]: any}) => {
    return await axios.post(apiUrl + apiPath, postParam)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('NETWORK ERROR');
      }
      if (!response.data.result) {
        throw new Error(JSON.stringify(response.data));
      }
      return response.data.param;
    }).catch((e) => {
      throw new Error(e.message);
    });
  },
  post: async (apiPath: string, token: string, postParam: {[key: string]: any}) => {
    axios.defaults.headers.common.Authorization = 'Bearer ' + token;
    return await axios.post(apiUrl + apiPath, postParam)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('NETWORK ERROR');
      }
      if (!response.data.result) {
        throw new Error(JSON.stringify(response.data));
      }

      if (response.data.param) {
        return response.data.param; // param umu
      } else {
        return null;
      }
    }).catch((e) => {
      throw new Error(e.message);
    });
  },
  put: async (apiPath: string, token: string, postParam: {[key: string]: any}) => {
    axios.defaults.headers.common.Authorization = 'Bearer ' + token;
    return await axios.put(apiUrl + apiPath, postParam)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('NETWORK ERROR');
      }
      if (!response.data.result) {
        throw new Error(JSON.stringify(response.data));
      }

      if (response.data.param) {
        return response.data.param; // param umu
      } else {
        return null;
      }
    }).catch((e) => {
      throw new Error(e.message);
    });
  },
  delete: async (apiPath: string, token: string, postParam: {[key: string]: any}) => {
    axios.defaults.headers.common.Authorization = 'Bearer ' + token;
    return await axios.delete(apiUrl + apiPath, postParam)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('NETWORK ERROR');
      }
      if (!response.data.result) {
        throw new Error(JSON.stringify(response.data));
      }

      if (response.data.param) {
        return response.data.param; // param umu
      } else {
        return null;
      }
    }).catch((e) => {
      throw new Error(e.message);
    });
  },
  getNoToken: async (apiPath: string, postParam: {[key: string]: any}) => {
    return await axios.get(apiUrl + apiPath, {params: postParam})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('NETWORK ERROR');
      }
      if (!response.data.result) {
        throw new Error(JSON.stringify(response.data));
      }
      return response.data.param;
    }).catch((e) => {
      throw new Error(e.message);
    });
  },
  get: (apiPath: string, token: string, postParam: {[key: string]: any}) => {
    axios.defaults.headers.common.Authorization = 'Bearer ' + token;
    return axios.get(apiUrl + apiPath, {params: postParam})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('NETWORK ERROR');
      }
      if (!response.data.result) {
        throw new Error(JSON.stringify(response.data));
      }
      return response.data.param; // param umu
    }).catch((e) => {
      throw new Error(e.message);
    });
  },
  asyncPost: (apiPath: string, token: string, postParam: {[key: string]: any}) => {
    axios.defaults.headers.common.Authorization = 'Bearer ' + token;
    axios.post(apiUrl + apiPath, postParam);
  },
  asyncPut: (apiPath: string, token: string, postParam: {[key: string]: any}) => {
    axios.defaults.headers.common.Authorization = 'Bearer ' + token;
    axios.put(apiUrl + apiPath, postParam);
  },
};
