import Vue from 'vue';
import Vuex from 'vuex';
import match   from '@/store/match';
import solo    from '@/store/solo';
import multi   from '@/store/multi';
import darts   from '@/store/darts';
import event   from '@/store/event';
import {RootParameter} from '@/model/interface/RootParameter';
import {Department}    from '@/model/class/department';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: '',
    usrType: 0,
    usrRole: 0,
    orgId: 0,
    usrId: 0,
    usrName: 'Guest',
    usrNameShort: 'Guest',
    // pref
    departments: [],
    roles: [],
    rolesFlg: {canEditInfo: false, canEditMsg: false, canEditEvent: false, canEditAdmin: false},
    messages: [],
    tableNum: 0,
    searchEvent: '',
    searchRegular: '',
  } as RootParameter,
  getters: {
    getToken: (state: RootParameter, getters: any) => (): string => {
      return state.token;
    },
    getUsrType: (state: RootParameter, getters: any) => (): number => {
      return state.usrType;
    },
    getUsrRole: (state: RootParameter, getters: any) => (): number => {
      return state.usrRole;
    },
    getOrgId: (state: RootParameter, getters: any) => (): number => {
      return state.orgId;
    },
    getUsrId: (state: RootParameter, getters: any) => (): number => {
      return state.usrId;
    },
    getUsrName: (state: RootParameter, getters: any) => (): string => {
      return state.usrName;
    },
    getUsrNameShort: (state: RootParameter, getters: any) => (): string => {
      return state.usrNameShort;
    },
    getDepartments: (state: RootParameter, getters: any) => (): Department[] => {
      return state.departments;
    },
    getRoles: (state: RootParameter, getters: any) => (): any[] => {
      return state.roles;
    },
    getRolesFlg: (state: RootParameter, getters: any) => (): {[key: string]: boolean} => {
      return state.rolesFlg;
    },
    getMessageCount: (state: RootParameter, getters: any) => (): number => {
      return state.messages.length;
    },
    getTableNum: (state: RootParameter, getters: any) => (): number => {
      return state.tableNum;
    },
    getSearchEvent: (state: RootParameter, getters: any) => (): string => {
      return state.searchEvent;
    },
    getSearchRegular: (state: RootParameter, getters: any) => (): string => {
      return state.searchRegular;
    },
  },
  mutations: {
    setToken(state: RootParameter, payload: string) {
      state.token = payload;
    },
    setUsrType(state: RootParameter, payload: number) {
      state.usrType = payload;
    },
    setUsrRole(state: RootParameter, payload: number) {
      state.usrRole = payload;
    },
    setOrgId(state: RootParameter, payload: number) {
      state.orgId = payload;
    },
    setUsrId(state: RootParameter, payload: number) {
      state.usrId = payload;
    },
    setUsrName(state: RootParameter, payload: string) {
      state.usrName = payload;
    },
    setUsrNameShort(state: RootParameter, payload: string) {
      state.usrNameShort = payload;
    },
    setDepartments(state: RootParameter, payload: Department[]) {
      state.departments = payload;
    },
    setTableNum(state: RootParameter, payload: number) {
      state.tableNum = payload;
    },
    setSearchEvent(state: RootParameter, payload: string) {
      state.searchEvent = payload;
    },
    setSearchRegular(state: RootParameter, payload: string) {
      state.searchRegular = payload;
    },
    setGuestParam(state: RootParameter) {
      state.token        = '';
      state.usrType      = 0;
      state.usrRole      = 0;
      state.orgId        = 0;
      state.usrId        = 0;
      state.usrName      = 'Guest';
      state.usrNameShort = 'Guest';
      state.departments  = [];
      state.roles        = [];
      state.rolesFlg     = {canEditInfo: false, canEditMsg: false, canEditEvent: false, canEditAdmin: false};
      state.messages     = [];
      state.tableNum     = 0;
    },
    setMemberParam(state: RootParameter, payload: RootParameter) {
      state.token        = payload.token;
      state.usrType      = payload.usrType;
      state.usrRole      = payload.usrRole;
      state.orgId        = payload.orgId;
      state.usrId        = payload.usrId;
      state.usrName      = payload.usrName;
      state.usrNameShort = payload.usrNameShort;
      state.departments  = payload.departments;
      state.roles        = payload.roles;
      state.rolesFlg     = payload.rolesFlg;
      state.messages     = payload.messages;
      state.tableNum     = payload.tableNum;
    },
  },
  actions: {
    loginAction(context: any, postParam: RootParameter) { // {[key: string]: number}
      context.commit('setMemberParam', postParam);
      window.localStorage.setItem('profile', JSON.stringify(postParam));
    },
    logoutAction(context: any) {
      context.commit('setGuestParam');
      window.localStorage.removeItem('profile');
    },
    searchEventAction(context: any, searchEvent: {[key: string]: any}) {
      context.commit('setSearchEvent', JSON.stringify(searchEvent));
    },
    searchRegularAction(context: any, searchRegular: {[key: string]: any}) {
      context.commit('setSearchRegular', JSON.stringify(searchRegular));
    },
  },
  modules: {
    match,
    solo,
    multi,
    event,
    darts,
  },
});
