





























import {Component, Prop, Vue} from 'vue-property-decorator';
import AdminHeader  from '@/components/admin/common/AdminHeader.vue';
import DeptTree     from '@/components/admin/DeptTree.vue';
import libAxios     from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    AdminHeader,
    DeptTree,
  },
})
export default class OrgTree extends Vue {
  private usrType: number = this.$store.getters.getUsrType();
  private usrRole: number = this.$store.getters.getUsrRole();
  private roles: any[] = this.$store.getters.getRoles();
  private isLoading: boolean = true;
  private orgId: number = 0;
  private orgName: string = '';

  private deptList: Array<{[key: string]: any}> = [];
  private breadItems: Array<{[key: string]: any}> = [
    { text: '管理Top', to: { name: 'admin-top' }},
    { text: '組織一覧', to: { name: 'admin-org-list' }},
  ];

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          this.orgId = parseInt(this.$route.params.id, 10);
          // 権限チェック 9  3  それ以外はロールがあれば
          // 新規作成
          // 編集 詳細 削除
          // if (this.usrRole === this.C_ROLE_SYSTEM) {
          this.getOrgTree();
          // }
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private getOrgTree() {
    this.isLoading = true;
    const token = this.$store.getters.getToken();
    libAxios.get('tree-for-org/' + this.orgId, token, {})
    .then((param: {[key: string]: any}) => {
      this.orgName = param.org.name;
      this.deptList = param.depts;
      this.breadItems.push({ text: this.orgName + ' 部門一覧', active: true});
      this.isLoading = false;
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }

  private roleList(deptId: number) {
    this.getRoleData(deptId);
  }

  private staffList(deptId: number) {
    this.getStaffData(deptId);
  }

  private deptOrder(deptId: number) {
    // 並び替え
  }

  private searchDept(deptId: number, depts: Array<{[key: string]: any}>) {
    // TODO
  }

  private async getRoleData(deptId: number) {
    const token = this.$store.getters.getToken();
    libAxios.get('role-data', token, {d_id: deptId})
    .then((param: {[key: string]: any}) => {

      this.isLoading = false;
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }

  private async getStaffData(deptId: number) {
    const searcParam = {
      d_id: deptId,
      u_type: 2,
    };
    const token = this.$store.getters.getToken();
    libAxios.get('role-data', token, searcParam)
    .then((param: {[key: string]: any}) => {

      this.isLoading = false;
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }
}
