










































































import {Component, Vue, Watch} from 'vue-property-decorator';
import Header           from '@/components/common/Header.vue';
import EventDetail      from '@/components/event/EventDetail.vue';
import libAxios        from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

interface EventItem {
  id: number;
  status: number;
  title: string;
  date: string;
  name: string;
  summary: string;
  body: string;
  img_path: string;
  img_text: string;
  isTitle: boolean;
}

@Component({
  components: {
    Header,
    EventDetail,
  },
})
export default class Event extends Vue {
  private usrType!: number; // デフォルトはゲスト
  private isLoading: boolean = true;
  private currentEvent: {[key: string]: any} = {};
  private currentTitle: string = '';
  private eventData: EventItem[] = [];
  private eventList: Array<{[key: string]: any}> = [];
  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'top' }},
  ];
  private modalEventInfo: boolean = false;
  private regularId: string = '';
  private regularTitle: string = '';

  private serachParam: {[key: string]: any} = {};
  private currentPage: number = 0;
  private rows!: number;
  private perPage: number = 12;

  @Watch('currentPage')
  private onTextChanged(newNum: number, oldNum: number) {
    this.serachParam.page_num = newNum;
    this.$store.dispatch('searchEventAction', this.serachParam);
    this.getEvents();
  }

  private created(): void {
    try {
      let pageNum = 1;
      const saveParam = this.$store.getters.getSearchEvent();
      if (saveParam) {
        this.serachParam = JSON.parse(saveParam);
      }
      if (this.serachParam.page_num) {
        pageNum = this.serachParam.page_num;
      }

      if (this.$route.path.substr(0, 14) === '/regular-event') {
        this.regularId = this.$route.params.id;
        this.serachParam.regular_event_id = this.$route.params.id;
        this.regularTitle = this.$route.params.str;
        this.breadItems.push({ text: '定例Event一覧', to: {name: 'regular-event'}});
        this.breadItems.push({ text: this.regularTitle + '一覧', active: true});
      } else {
        // TODO
        this.breadItems.push({ text: 'Event一覧', active: true});
      }
      this._checkLogin().
      then((isLogin: boolean) => {
        this._getResumeData(0); // Gameではないので戻り値は無視
        this.usrType = this.$store.getters.getUsrType();
        this.currentPage = pageNum;
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private async getEvents() {
    if (this.usrType === this.C_MODE_GUEST) {
      this.getEventsForGuest();
    } else {
      this.getEventsForMember();
    }
  }

  private async getEventsForMember() {
    const token = this.$store.getters.getToken();
    return await libAxios.get('event-info', token, this.serachParam)
    .then((param: {[key: string]: any}) => {
      this.rows = param.cnt;
      this.eventList = param.data;
      //
      this.isLoading = false;
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }

  private async getEventsForGuest() {
    return await libAxios.getNoToken('event-list', this.serachParam)
    .then((param: {[key: string]: any}) => {
      this.rows = param.cnt;
      this.eventList = param.data;
      this.isLoading = false;
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }

  private toDetail(eventId: string) {
    if (this.regularId === '') {
      this.$router.push({name: 'event-info', params: {id: eventId}});
    } else {
      this.$router.push({
        name: 'regular-event-event-info',
        params: {id1: this.regularId, id2: eventId, str: this.regularTitle},
      });
    }
  }

  private setEventItems(param: Array<{[key: string]: any}>) {
    this.eventData = []; // 空にする
    for (const item of param) {
      const tmpEventItem: EventItem = {
        id: item.e_id,
        status: item.e_status,
        title: item.title,
        date: item.first_dt.substr(0, 10),
        name: item.from_name,
        summary: item.summary,
        body: item.body,
        img_path: item.img_path,
        img_text: item.img_text,
        isTitle: true,
      };
      if (item.e_status === this.C_EVENT_FIN) {
        tmpEventItem.summary = item.rst_summary;
        tmpEventItem.body = item.rst_body;
        tmpEventItem.img_path = item.rst_img_path;
        tmpEventItem.img_text = item.rst_img_text;
      }
      if (tmpEventItem.img_path === '') {
        tmpEventItem.img_path = '/img/no_image.png';
      }
      this.eventData.push(tmpEventItem);
    }
  }

private setEventItemsGuest(param: Array<{[key: string]: any}>) {
    this.eventData = []; // 空にする
    for (const item of param) {
      const tmpEventItem: EventItem = {
        id: item.e_id,
        status: item.e_status,
        title: item.title,
        date: item.first_dt.substr(0, 10),
        name: item.from_name,
        summary: item.summary,
        body: item.body,
        img_path: item.img_path,
        img_text: item.img_text,
        isTitle: true,
      };

      if (item.e_status === this.C_EVENT_FIN) {
        tmpEventItem.summary = item.rst_summary;
        tmpEventItem.body = item.rst_body;
        tmpEventItem.img_path = item.rst_img_path;
        tmpEventItem.img_text = item.rst_img_text;
      }
      if (tmpEventItem.img_path === '') {
        tmpEventItem.img_path = '/img/no_image.png';
      }
      this.eventData.push(tmpEventItem);
    }
  }

  private showInfo(num: number) {
    this.currentEvent = this.eventList[num];
    this.currentTitle = this.currentEvent.title;
    this.modalEventInfo = true;
  }
}
