








import {Component, Vue} from 'vue-property-decorator';
import Header from '@/components/common/Header.vue'; // @ is an alias to /src
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
  },
})
export default class Contact extends Vue {
  private created(): void {
    try {
      this._checkLogin().
      then(() => {
        this._getResumeData(0); // Gameではないので戻り値は無視
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      window.console.log(e.message);
      this.$router.push({name: 'error'});
    }
  }
}
