

































































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {EventStep}     from '@/model/class/EventStep';
import {PlayerParameter} from '@/model/interface/PlayerParameter';
import {EventPlayer}     from '@/model/class/EventPlayer';
import DeptPlayerPanel   from '@/components/score/common/DeptPlayerPanel.vue';
import EventPlayerEdit   from '@/components/event-admin/EventPlayerEdit.vue';
import libAxios          from '@/mixin/LibAxios';
import BootstrapVue      from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    DeptPlayerPanel,
    EventPlayerEdit,
  },
})
export default class EventStep0Data extends Vue {
  private usrType: number = this.$store.getters.getUsrType();
  private currentStep: EventStep = this.$store.getters['event/getStep'](0);
  private stepLen: number = this.$store.getters['event/getStepsLength']();
  private stepStatus: number = this.$store.getters['event/getStepStatus'](0);
  private guestCount: number = 1;
  private playerTab: number = 0;
  private PlayerParam: PlayerParameter = {id: 0, name: '', nameShort: ''};
  private modalAddMember: boolean = false;
  private modalEditPlayer: boolean = false;
  private playerIsEdit: boolean  = false;
  private playerEditNum: number = -1;
  private eventPlayers!: EventPlayer[];
  private editPlayer: {[key: string]: any} = {};

  @Emit('executeStep') private executeStep(stepId: number, param: {[key: string]: any}): void { return; }
  @Emit('storePlayer') private storePlayer(stepId: number, param: {[key: string]: any}): void { return; }
  @Emit('updatePlayer')
  private updatePlayer(stepId: number, playerID: number, param: {[key: string]: any}): void { return; }
  @Emit('movePlayer') private movePlayer(eventStep: EventStep): void { return; }
  @Emit('destroyStep') private destroyStep(stepId: number, param: {[key: string]: any}): void { return; }


  private get countPlayers(): number {
    return this.eventPlayers.length;
  }

  private created(): void {
    this.eventPlayers = [];
    const tmpPlayers = this.$store.getters['event/getPlayers']();
    const step0Id = this.$store.getters['event/getStepId'](0);
    for (const key in tmpPlayers) {
      if (tmpPlayers[key].eventStepId === step0Id) {
        this.eventPlayers.push(tmpPlayers[key]);
      }
    }
    this.eventPlayers.sort((a, b) => {
      if (a.setOrder > b.setOrder) {
        return 1;
      } else {
        return -1;
      }
    });
  }

  private addGuest(): void {
    if (confirm(this.guestCount + '人のGUESTを追加しますか？')) {
      const postParam = {
        is_member:  false,
        ids:        0,
        num_of_ppl: this.guestCount,
      };
      this.storePlayer(this.currentStep.eventStepId, postParam);
    }
  }

  private addSelf(): void {
    if (confirm('自分自身を追加しますか？')) {
      const postParam = {
        is_member:  true,
        ids:        this.$store.getters.getUsrId(),
        num_of_ppl: 1,
      };
      this.storePlayer(this.currentStep.eventStepId, postParam);
    }
  }

  private addMember(num: number): void {
    this.playerIsEdit = false;
    this.playerEditNum = num;
    this.modalAddMember = true;
  }

  private setPlayer(playerTab: number, player: PlayerParameter): void {
    this.playerTab = playerTab;
    this.PlayerParam = player;
    this.playerIsEdit = true;
  }

  private addPlayer(): void {
    let tmpId = 0;
    if (this.playerIsEdit) {
      tmpId = this.PlayerParam.id;
    }
    if (tmpId === 0) {
      alert('Memberを選択してください');
    } else {
      const postParam = {
        is_member:  true,
        ids:        tmpId,
        num_of_ppl: 1,
      };
      this.storePlayer(this.currentStep.eventStepId, postParam);
    }
  }

  private setEditPlayer(evnetPlayerId: number): void {
    if (this.stepStatus >= this.C_STEP_END) {
      return;
    }

    const tmpPlayer = this.$store.getters['event/getPlayer'](evnetPlayerId);
    this.editPlayer = {};
    this.editPlayer.eventPlayerId = tmpPlayer.eventPlayerId;
    this.editPlayer.usrName       = tmpPlayer.usrName;
    this.editPlayer.usrNameShort  = tmpPlayer.usrNameShort;
    this.editPlayer.deptName      = tmpPlayer.deptName;
    this.editPlayer.deptNameShort = tmpPlayer.deptNameShort;
    this.editPlayer.usrClass      = tmpPlayer.usrClass;
    this.editPlayer.usrSex        = tmpPlayer.usrSex;
    this.editPlayer.canReferee    = this._bitCalc(tmpPlayer.eventPlayerStatus, 2);
    this.modalEditPlayer = true;
  }

  private setEditPlayerParam(key: string, val: any): void {
    this.editPlayer[key] = val;
  }

  private modPlayer(): void {
    const postParam = {
      u_name:     this.editPlayer.usrName,
      u_name_s:   this.editPlayer.usrNameShort,
      d_name:     this.editPlayer.deptName,
      d_name_s:   this.editPlayer.deptNameShort,
      u_class:    this.editPlayer.usrClass,
      u_sex:      this.editPlayer.usrSex,
      is_referee: this.editPlayer.canReferee,
    };
    this.updatePlayer(this.currentStep.eventStepId, this.editPlayer.eventPlayerId, postParam);
  }

  private sortPlayer(): void {
    this.movePlayer(this.currentStep);
  }

  private fixStep0(lv: number): void {
    if (confirm('確定しますか？')) {
      const postParam = {
        command: 'end',
        scorp: lv,
      };
      this.executeStep(this.currentStep.eventStepId, postParam);
    }
  }

  private async delStep() {
    if (this.stepLen !== 1) {
      return; // 念のためチェック
    }
    // 試合のチェックは不要
    const password = prompt('削除パスワードを入力してください。？');
    if (password === 'yyw') {
      const param = {
        password,
        edit_dt: this.currentStep.editDateTime,
      };
      this.destroyStep(this.currentStep.eventStepId, param);
    }
  }
}
