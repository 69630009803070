







import {Component, Prop, Emit, Vue} from 'vue-property-decorator';
import {EventGame}     from '@/model/class/EventGame';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class TournamentLine extends Vue {
  @Prop() private stepId!: number;
  @Prop() private setNum!: number;
  @Prop() private tnmId!: string;
  @Prop() private lineSize!: string;
  @Prop() private lineType!: string;
  private lineStyle: string = '';
  private score1: number|string = '';
  private score2: number|string = '';

  private created(): void {
    const key = this.stepId + '_' + this.setNum + this.tnmId;
    const tmpGame: EventGame = this.$store.getters['event/getGame'](key);
    this.lineStyle = 'tnm-line' + this.lineSize + ' type' + this.lineType + '-' + tmpGame.winner;
    if (this.lineSize !== '15x52l' && this.lineSize !== '15x128l') {
      this.score1 = tmpGame.score1;
      this.score2 = tmpGame.score2;
    }
  }
}
