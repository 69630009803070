





















































import {Component, Prop, Vue} from 'vue-property-decorator';
import Header           from '@/components/common/Header.vue';
import BowlardListTable from '@/components/score/solo/BowlardListTable.vue';
import libAxios         from '@/mixin/LibAxios';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
    BowlardListTable,
  },
})
export default class ScoreSoloList extends Vue {
  private isLoading: boolean = true;
  private usrType!: number;
  private gameName!: string;
  private gameNum!: number;
  private nextUrl!: {name: string};
  private searchParam!: {[key: string]: any};
  private gameList!: any[];

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          this._getResumeData(0); // Gameではないので戻り値は無視
          this.usrType = this.$store.getters.getUsrType();
          this.gameName = this.$route.params.type;

          if (this.gameName === this.C_NAME_BOWLARD) {
            this.gameNum = this.C_NUM_BOWLARD;
            this.nextUrl = {name: 'score-bowlard'};
          } else {
            throw new Error('Unknown Game Type');
          }
          this.getSoloScoreList({}, 0);
        } else {
          this.$router.push({name: 'top'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      window.console.log(e.message);
      this.$router.push({name: 'error'});
    }
  }

  private getSoloScoreList(searchParam: {[key: string]: any}, pageNum: number) {
    // 検索情報を取得
    // ローカルから取得
    // 時刻確認
    // データ取得
    // 条件なしは保存する
    // const saveProfile: string|null = window.localStorage.getItem('score-list-9ball');
    searchParam.usr_id = this.$store.getters.getUsrId();
    searchParam.game_type = this.gameNum;
    searchParam.page_num = pageNum;
    const token = this.$store.getters.getToken();
    libAxios.get('game-data/solo', token, searchParam)
    .then((response) => {
      const tempList: any[] = [];
      const nowDT = this._formatDate(new Date(), 'Y-m-d');
      const usrID = this.$store.getters.getUsrId();
      for (const item of response.data) {
        const tmpItem: {[key: string]: any} = {};
        let tmpViewDT = item.start_dt;
        if (tmpViewDT.substr(0, 10) === nowDT) {
          tmpItem.isToday = true;
          tmpViewDT = tmpViewDT.substr(11, 5);
        } else {
          tmpItem.isToday = false;
          tmpViewDT = tmpViewDT.substr(5, 5);
        }
        tmpItem.viewDT = tmpViewDT;
        tmpItem.score = item.score;
        tmpItem.place = item.d_name;
        if (item.d_id !== 0 && item.d_tbl !== 0) {
          tmpItem.place += ' ' + item.d_tbl + '番台';
        }
        tmpItem.title = item.e_name;
        tmpItem.param = item.param;
        tmpItem.id = item.gs_id;
        if (item.e_id === 0) {
          tmpItem.canDel = true;
        } else {
          tmpItem.canDel = false;
        }
        tempList.push(tmpItem);
      }
      this.gameList = tempList;
      this.isLoading = false;
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }

  private async delSoloScore(id: number) {
    if (!window.confirm('削除してよいですか？\n削除したデータの復旧はできません。')) {
      return;
    }
    this.isLoading = true;
    const token = this.$store.getters.getToken();
    return await libAxios.delete('game-data/solo/' + id, token, {})
    .then(() => {
      this.getSoloScoreList({}, 0);
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }
}
