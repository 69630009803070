











































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {MatchParam}       from '@/model/interface/MatchParam';
import {MatchPlayerParam} from '@/model/interface/MatchPlayerParam';
import {MatchDataParam}   from '@/model/interface/MatchDataParam';
import {PlayPointParam}   from '@/model/interface/PlayPointParam';
import MatchHeader        from '@/components/score/match/MatchHeader.vue';
import NamePlate          from '@/components/score/match/NamePlate.vue';
import ConfigRaceTo       from '@/components/score/match/ConfigRaceTo.vue';
import Game10BallConfig   from '@/components/score/match/Game10BallConfig.vue';
import MatchConfig        from '@/components/score/match/MatchConfig.vue';
import PointNormalPlate   from '@/components/score/match/PointNormalPlate.vue';
import GameStartPanel     from '@/components/score/match/GameStartPanel.vue';
import Game10BallBreak    from '@/components/score/match/Game10BallBreak.vue';
import YesNoPanel         from '@/components/score/match/YesNoPanel.vue';
import Game10BallPlayLv1  from '@/components/score/match/Game10BallPlayLv1.vue';
import Game10BallPlay     from '@/components/score/match/Game10BallPlay.vue';
import StopWatch          from '@/components/common/StopWatch.vue';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    MatchHeader,
    NamePlate,
    ConfigRaceTo,
    Game10BallConfig,
    MatchConfig,
    PointNormalPlate,
    GameStartPanel,
    Game10BallBreak,
    YesNoPanel,
    Game10BallPlayLv1,
    Game10BallPlay,
    StopWatch,
  },
})
export default class Game10Ball extends Vue {
  private isLoading: boolean = true;
  private isSetting: boolean = true;
  private isReverse: boolean = false;
  private usrType!: number;
  private eventId!: number;
  private matchData!: MatchDataParam;
  private baseTime: number = 0;

  private get scoreLv(): number {
    return this.$store.getters['match/getConfigParam']('scoreLv');
  }

  private get useWatch(): number {
    return this.$store.getters['match/getConfigParam']('stopWatch');
  }

  private created(): void {
    try {
      this._checkLogin().
      then((result: boolean) => {
        this.usrType = this.$store.getters.getUsrType();
        this.eventId = this.$store.getters['match/getEventId']();
        this._created();
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      window.console.log(e.message);
      this.$router.push({name: 'error'});
    }
  }

  private _created(): void {
    const suspend = this._getResumeData(this.C_NUM_10B);
    if (suspend === '') {
      this.$router.push({name: 'error'}); // SYSTEM ERROR
    }
    const tmpGameId = this.$store.getters['match/getGameId']();
    if (tmpGameId !== 0) {
      if (tmpGameId !== suspend.gameId) {
        this.$router.push({name: 'error'}); // SYSTEM ERROR
      }
    } else { // suspendからすべてを復活させる
      this.$store.dispatch('match/resumeAction', suspend);
    }

    const tmpStatus = this.$store.getters['match/getGameStatus']();
    this.matchData = {
      viewMode: this.C_VIEW_START,
      isFirst: true, // Player1?
      firstPlayer: 0,
      gameNum: -1,
      gameParam: [],
      player1foul: 0, // 使う
      player2foul: 0, // 使う
      balls: [],
      isRunOut: true, // 使う
      canPush: true, // 使う
    };

    if (tmpStatus === this.C_STATUS_INIT) {
      let tmpConfig = Object.assign(this.C_CFG_10B_PARAM, this.C_CFG_MATCH_COMMON);
      tmpConfig = this._getConfig(tmpConfig, this.C_NAME_10B);
      if (this.eventId !== 0) {
        const eventConfig = this.$store.getters['match/getConfig']();
        for (const param of Object.keys(eventConfig)) {
          tmpConfig[param] = eventConfig[param];
        }
      }
      this.$store.dispatch('match/setConfigAction', tmpConfig);
    } else if (tmpStatus === this.C_STATUS_READY) {
      this.isSetting = false;
    } else if (tmpStatus === this.C_STATUS_PLAY) {
      this.matchData.firstPlayer = this.$store.getters['match/getFirst']();

      // ここで C_STATUS_PLAY
      const dateTime = new Date() ;
      this.baseTime = Math.floor( dateTime.getTime() / 1000 ) ;
      const tmpParam = this.$store.getters['match/getMatchGameParam']();

      if (tmpParam === '') {
        this.toBreak(this.matchData.firstPlayer);
      } else {
        this.matchData.gameParam = tmpParam.split(',');
        this.matchData.gameNum = this.matchData.gameParam.length - 1;

        const tmpBuf = this.matchData.gameParam[this.matchData.gameNum].split(';');
        this.calcParam(tmpBuf);
      }
      const tmpWinner = this.$store.getters['match/getWinner']();
      if (tmpWinner !== 0) {
        this.matchData.viewMode = this.C_VIEW_FIN;
      }
      this.isSetting = false;
    } else {
      // TODO
    }
    this.isLoading = false;
    this.$forceUpdate();
  }

  private calcParam(params: string[]): void {
    if (params[0] === 'A' ) {
      this.matchData.isFirst = true;
    } else if (params[0] === 'B' ) {
      this.matchData.isFirst = false;
    } else {
      return;
    }

    this.matchData.viewMode = this.C_VIEW_BREAK;

    const maxLen = params.length;
    for (let i = 1; i < maxLen; i++) {
      const tmp1Set = params[i].split(':');
      const tmpBalls: number[] = [];
      if (tmp1Set.length !== 1) {
        const strLen = tmp1Set[1].length;
        for (let j = 0; j < strLen; j++) {
          tmpBalls.push(parseInt(tmp1Set[1].charAt(j), 16));
        }
      }
      this.execAction(tmp1Set[0], tmpBalls, false);
    }
  }

  private toGame(): void {
    // Status
    const tmpStatus: number = this.$store.getters['match/getGameStatus']();
    if (tmpStatus < this.C_STATUS_READY) {
      this.$store.dispatch('match/readyAction');
    } else {
      this.$store.dispatch('match/toGameAction');
    }
    this.isSetting = false;
  }

  private gameStart(playerNum: number): void {
    this.matchData.firstPlayer = playerNum;
    this.matchData.gameNum = -1;
    this.matchData.gameParam = [];

    // ここで C_STATUS_PLAY
    const dateTime = new Date() ;
    this.baseTime = Math.floor( dateTime.getTime() / 1000 ) ;

    const startParam = {
      startDateTime: this._formatDate(dateTime, ''),
      first: playerNum,
    };
    this.$store.dispatch('match/startAction', startParam);
    this.toBreak(playerNum);
  }

  private undo(): void {
    let maxNum = this.matchData.gameNum;
    let lastParam = this.matchData.gameParam[maxNum];
    if (lastParam.length === 1) {
      if (maxNum === 0) {
        this.matchData.viewMode = this.C_VIEW_START;
        this.$forceUpdate();
        return;
      } else {
        maxNum--;
        this.matchData.gameNum--;
        this.matchData.gameParam.pop();
        lastParam = this.matchData.gameParam[maxNum];
        this.$store.dispatch('match/popResultAction');
      }
    } else { // 勝敗がきまっていたらリセット
      const winner = this.$store.getters['match/getWinner']();
      if (winner !== this.C_WINNER_TBD) {
        this.$store.dispatch('match/popResultAction');
      }
    }
    this.$store.dispatch('match/setWinnerAction', 0);
    const tmpBuf = lastParam.split(';');
    tmpBuf.pop();
    this.matchData.gameParam[maxNum] = tmpBuf.join(';');
    this.calcParam(tmpBuf);
    this.$forceUpdate();
  }

  private toBreak(playerNum: number): void {
    if (playerNum === 1) {
      this.matchData.isFirst = true;
      this.matchData.gameParam.push('A');
    } else {
      this.matchData.isFirst = false;
      this.matchData.gameParam.push('B');
    }
    this.matchData.gameNum++;
    this.matchData.player1foul = 0;
    this.matchData.player2foul = 0;
    this.matchData.balls = [];
    this.matchData.isRunOut = true;
    this.matchData.canPush = true;

    this.matchData.viewMode = this.C_VIEW_BREAK;
    this.$forceUpdate();
  }

  private postPlayData(): void {
    const dateTime = new Date() ;
    const nowDate = Math.floor( dateTime.getTime() / 1000 ) ;

    const saveParam: {[key: string]: any} = {
      endDateTime: this._formatDate(dateTime, ''),
      playTimeSpan: nowDate - this.baseTime,
      param: this.matchData.gameParam.join(','),
    };
    this.$store.dispatch('match/saveAction', saveParam);
  }

  private actionType(action: string, balls: number[]): void {
    this.execAction(action, balls, true);
  }

  private execAction(action: string, balls: number[], isView: boolean): void {
    let pointParam!: PlayPointParam;
    switch (action) {
      case 'm': // ブレイクno-in
        pointParam = {mode: this.C_VIEW_BREAK,  next: this.C_VIEW_PLAY,   change: true,  foul: false, point: false};
        break;
      case 'c': // ブレイク change
        pointParam = {mode: this.C_VIEW_BREAK,  next: this.C_VIEW_BREAK,  change: true,  foul: true, point: false};
        break;
      case 'p': // break in
        pointParam = {mode: this.C_VIEW_BREAK,  next: this.C_VIEW_PLAY,   change: false, foul: false, point: false};
        break;
      case 'g': // ブレイクスクラッチ
        pointParam = {mode: this.C_VIEW_BREAK,  next: this.C_VIEW_PLAY,   change: true,  foul: true, point: false};
        break;
      case 'f': // ブレイクファール
        pointParam = {mode: this.C_VIEW_BREAK,  next: this.C_VIEW_PLAY,   change: true,  foul: true, point: false};
        break;
      case 'J': // Pushからのプレイ
        pointParam = {mode: this.C_VIEW_OPTION, next: this.C_VIEW_PLAY,   change: false, foul: false, point: false};
        break;
      case 'K': // Pushからのパス
        pointParam = {mode: this.C_VIEW_OPTION, next: this.C_VIEW_PLAY,   change: true,  foul: false, point: false};
        break;
      case 'H': // Push
        pointParam = {mode: this.C_VIEW_PLAY,   next: this.C_VIEW_OPTION, change: true,  foul: false, point: false};
        break;
      case 'W': // win
        pointParam = {mode: this.C_VIEW_PLAY,   next: this.C_VIEW_BREAK,  change: false, foul: false, point: true};
        break;
      case 'R': // runout
        pointParam = {mode: this.C_VIEW_PLAY,   next: this.C_VIEW_BREAK,  change: false, foul: false, point: true};
        break;
      case 'V': // win -相手
        pointParam = {mode: this.C_VIEW_PLAY,   next: this.C_VIEW_BREAK,  change: true,  foul: false, point: true};
        break;
      case 'S': // safety
        pointParam = {mode: this.C_VIEW_PLAY,   next: this.C_VIEW_PLAY,   change: true,  foul: false, point: false};
        break;
      case 'M': // MISS
        pointParam = {mode: this.C_VIEW_PLAY,   next: this.C_VIEW_PLAY,   change: true,  foul: false, point: false};
        break;
      case 'G': // スクラッチ
        pointParam = {mode: this.C_VIEW_PLAY,   next: this.C_VIEW_PLAY,   change: true,  foul: true, point: false};
        break;
      case 'F': // ファール
        pointParam = {mode: this.C_VIEW_PLAY,   next: this.C_VIEW_PLAY,   change: true,  foul: true, point: false};
        break;
      case 'P':
        pointParam = {mode: this.C_VIEW_PLAY,   next: this.C_VIEW_PLAY,   change: false,  foul: false, point: false};
        break;
      case 'O': // option
        pointParam = {mode: this.C_VIEW_PLAY,   next: this.C_VIEW_OPTION, change: true,   foul: true, point: false};
        break;
      default:
        // ERROR
        break;
    }

    const len = balls.length;
    let str = '';
    if (pointParam.mode === this.C_VIEW_BREAK) {
      for (let i = 0; i < len; i++) {
        str += balls[i].toString(16);
      }

      this.matchData.balls = balls.filter((val) => (val !== 10));
       // ファールだったらプッシュなし
      if (action !== 'c' && pointParam.foul) {
         this.matchData.canPush = false;
      }
    } else if (pointParam.mode === this.C_VIEW_OPTION) {
      this.matchData.canPush = false;
    } else if (pointParam.mode === this.C_VIEW_PLAY) {
      if (action !== 'H') {
        this.matchData.canPush = false;
      }
      for (let i = 0; i < len; i++) {
        if (this.matchData.balls.indexOf(balls[i]) === -1) {
          str += balls[i].toString(16);
        }
      }

      // 勝利以外は10を削除
      if (action !== 'W') {
        balls = balls.filter((val) => (val !== 10));
      }
      Array.prototype.push.apply(this.matchData.balls, balls);

      if (this.matchData.isRunOut) {
        if (this.scoreLv === 1 && action !== 'R') {
          this.matchData.isRunOut = false; // select来た時点でマス割なし
        } else if (this.scoreLv === 2 && action === 'W' && this.matchData.balls.length !== 10) {
          this.matchData.isRunOut = false;
        }
      }
    }
    if (isView) {
      this.matchData.gameParam[this.matchData.gameNum] += ';' + action + ':' + str;
      this.calcPoint(pointParam);
    } else {
      this.calcSimple(pointParam);
    }
  }

  private calcSimple(pointParam: PlayPointParam): void {
    if (pointParam.foul) {
      if (this.matchData.isFirst) {
        this.matchData.player1foul++;
      } else {
        this.matchData.player2foul++;
      }
    } else {
      if (this.matchData.isFirst) {
        this.matchData.player1foul = 0;
      } else {
        this.matchData.player2foul = 0;
      }
    }

    if (pointParam.change) {
      this.matchData.isFirst = !this.matchData.isFirst;
      this.matchData.isRunOut = false; // select来た時点でマス割なし
    }

    this.matchData.viewMode = pointParam.next;
  }

  private calcPoint(pointParam: PlayPointParam): void {
    const threeFoul = this.$store.getters['match/getConfigParam']('threeFoul');
    if (threeFoul) { // 3ファールがある。
      if (pointParam.foul) {
        if (this.matchData.isFirst) {
          this.matchData.player1foul++;
          if (this.matchData.player1foul >= 3) {
            pointParam.point = true;
          }
        } else {
          this.matchData.player2foul++;
          if (this.matchData.player2foul >= 3) {
            pointParam.point = true;
          }
        }
      } else {
        if (this.matchData.isFirst) {
          this.matchData.player1foul = 0;
        } else {
          this.matchData.player2foul = 0;
        }
      }
    }

    if (pointParam.change) {
      this.matchData.isFirst = !this.matchData.isFirst;
      this.matchData.isRunOut = false; // select来た時点でマス割なし
    }

    let tmpWinner = 0;
    // 勝者確認
    if (pointParam.point === true) {
      this.$store.dispatch(
        'match/addResultAction',
        {isFirst: this.matchData.isFirst, addPoint: 1, isRunOut: this.matchData.isRunOut},
      );
      if (this.matchData.isFirst) {
        if (this.$store.getters['match/getPlayer1Score']() >= this.$store.getters['match/getPlayer1RaceTo']()) {
          this.$store.dispatch('match/setWinnerAction', this.C_WINNER_P1);
          tmpWinner = this.C_WINNER_P1;
        }
      } else {
        if (this.$store.getters['match/getPlayer2Score']() >= this.$store.getters['match/getPlayer2RaceTo']()) {
          this.$store.dispatch('match/setWinnerAction', this.C_WINNER_P2);
          tmpWinner = this.C_WINNER_P2;
        }
      }
    }

    if (tmpWinner === 0) {
      if (pointParam.point === true) {
        // 次のゲームへbreak
        let tmpBreak!: number;
        const breakOrder = this.$store.getters['match/getConfigParam']('breakOrder');
        if (breakOrder === 1) { // 勝者
          tmpBreak = (this.matchData.isFirst) ? 1 : 2;
        } else if (breakOrder === 2) { // 交互
          const amari = this.matchData.gameNum % 2;
          if (amari === 0) {
            tmpBreak = (this.matchData.firstPlayer === 1) ? 2 : 1 ;
          } else {
            tmpBreak = this.matchData.firstPlayer;
          }
        } else if (breakOrder === 3) { // 敗者
          tmpBreak = (this.matchData.isFirst) ? 2 : 1;
        } else if (breakOrder === 4) { // 固定
          tmpBreak = this.matchData.firstPlayer;
        }

        this.toBreak(tmpBreak);
      } else {
        this.matchData.viewMode = pointParam.next;
      }
    } else {
      // WIN
      this.matchData.viewMode = this.C_VIEW_FIN;
    }
    // status check
    this.postPlayData();
    this.$forceUpdate();
  }

  private gameFin(isFin: boolean): void {
    this.isLoading = true;
    this.isSetting = true;
    try {
      if (this.usrType === this.C_MODE_GUEST) { // ゲストの場合はデータ破棄
        if (isFin) {
          this.$store.dispatch('match/destroyAction');
          this.$router.push({name: 'score'});
        } else {
          this.$store.dispatch('match/retryGuestAction');
          this._created();
        }
      } else {
        this.$store.dispatch('match/finAction', isFin)
        .then(() => {
          if (this.eventId !== 0) {
            this.$router.push({ name: 'event-match', params: {type: this.C_NAME_MATCH} });
          } else {
            if (isFin) {
              if (this.usrType === this.C_MODE_MEMBER) {
                this.$router.push({name: 'score-list', params: {type: this.C_NAME_10B}});
              } else {
                this.$router.push({name: 'score'});
              }
            } else {
              this.$store.dispatch('match/retryAction')
              .then(() => {
                this._created();
              }).catch((e) => {
                throw new Error(e.message);
              });
            }
          }
        }).catch((e) => {
          throw new Error(e.message);
        });
      }
    } catch (e) {
      window.console.log(e.message);
      this.matchData.viewMode = this.C_VIEW_ERROR;
      this.isLoading = true;
    }
  }

  private reverse(): void {
    this.isReverse = !this.isReverse;
  }

  private setting(): void {
    this.isSetting = true;
  }
}
