














































import {Component, Prop, Vue} from 'vue-property-decorator';
import AdminHeader  from '@/components/admin/common/AdminHeader.vue';
import libAxios     from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    AdminHeader,
  },
})
export default class OrgList extends Vue {
  private isLoading: boolean = true;
  private usrType: number = this.$store.getters.getUsrType();
  private usrRole: number = this.$store.getters.getUsrRole();
  private rolesFlg: {canEditInfo: boolean, canEditMsg: boolean, canEditEvent: boolean, canEditAdmin: boolean}
    = this.$store.getters.getRolesFlg();


  private orgList: any[] = [];
  private breadItems: Array<{[key: string]: any}> = [
    { text: '管理Top', to: { name: 'admin-top' }},
    { text: '組織一覧', active: true},
  ];

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          if (!this.rolesFlg.canEditAdmin) {
            this.$router.push({name: 'error'});
          }
          this.getOrgList();
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private getOrgList() {
    this.isLoading = true;
    const token = this.$store.getters.getToken();
    libAxios.get('org-for-org', token, {})
    .then((param: {[key: string]: any}) => {
      this.orgList = param.data;
      this.isLoading = false;
      return;
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }
}
