



























































import {Component, Prop, Vue} from 'vue-property-decorator';
import Header        from '@/components/common/Header.vue';
import {MatchParam}       from '@/model/interface/MatchParam';
import {MatchPlayerParam} from '@/model/interface/MatchPlayerParam';
import {MatchDataParam}   from '@/model/interface/MatchDataParam';
import libAxios     from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
  },
})
export default class ScoreMultiList extends Vue {
  private isLoading: boolean = true;
  private usrType!: number;
  private gameName!: string;
  private gameNum!: number;
  private nextUrl!: {name: string};
  private setType!: number; // 1 solo 2 match-member 3 match-staff
  private searchParam!: {[key: string]: any};
  private gameList!: any[];

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          this._getResumeData(0); // Gameではないので戻り値は無視
          this.usrType = this.$store.getters.getUsrType();
          this.gameName = this.$route.params.type;

          if (this.gameName === this.C_NAME_BOWLARD) {
            this.gameNum = this.C_NUM_BOWLARD;
            this.setType = 1;
            this.nextUrl = {name: 'score-bowlard'};
          } else if (this.gameName === this.C_NAME_9B) {
            this.gameNum = this.C_NUM_9B;
            this.setType = 2;
            this.nextUrl = {name: 'score-9ball'};
          } else if (this.gameName === this.C_NAME_10B) {
            this.gameNum = this.C_NUM_10B;
            this.setType = 2;
            this.nextUrl = {name: 'score-10ball'};
          } else if (this.gameName === this.C_NAME_14_1) {
            this.gameNum = this.C_NUM_14_1;
            this.setType = 2;
            this.nextUrl = {name: 'score-14one'};
          } else if (this.gameName === this.C_NAME_ROTE) {
            this.gameNum = this.C_NUM_ROTE;
            this.setType = 2;
            this.nextUrl = {name: 'score-rotation'};
          } else if (this.gameName === this.C_NAME_POINT) {
            this.gameNum = this.C_NUM_POINT;
            this.setType = 3;
            this.nextUrl = {name: 'score-point'};
          } else if (this.gameName === this.C_NAME_FREE_POINT) {
            this.gameNum = this.C_NUM_FREE_POINT;
            this.setType = 3;
            this.nextUrl = {name: 'score-free-point'};
          } else {
            throw new Error('引数エラー');
          }
          if (this.setType === 1) {
            this.getSoloScoreList({}, 0);
          } else if (this.setType === 2) {
            this.getScoreList({}, 0);
          } else if (this.setType === 3) {
            this.getMultiScoreList({}, 0);
          }
        } else {
          this.$router.push({name: 'top'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      window.console.log(e.message);
      this.$router.push({name: 'error'});
    }
  }

  private getScoreList(searchParam: {[key: string]: any}, pageNum: number) {
    // 検索情報を取得
    // ローカルから取得
    // 時刻確認
    // データ取得
    // 条件なしは保存する
    // const saveProfile: string|null = window.localStorage.getItem('score-list-9ball');
    searchParam.usr_id = this.$store.getters.getUsrId();
    searchParam.game_type = this.gameNum;
    searchParam.page_num = pageNum;
    const token = this.$store.getters.getToken();
    libAxios.get('game-data/match', token, searchParam)
    .then((response) => {
      const tempList: any[] = [];
      const nowDT = this._formatDate(new Date(), 'Y-m-d');
      const usrID = this.$store.getters.getUsrId();
      for (const item of response.data) {
        const tmpItem: {[key: string]: any} = {};
        if (item.u_id_1 === usrID) {
          tmpItem.plyerName = item.u_name_2;
          tmpItem.score1    = item.score_1;
          tmpItem.score2    = item.score_2;
          tmpItem.raceTo1   = item.race_to_1;
          tmpItem.raceTo2   = item.race_to_2;
          tmpItem.result    = (item.winner === 1) ? 'W' : '';
        } else {
          tmpItem.plyerName = item.u_name_1;
          tmpItem.score1    = item.score_2;
          tmpItem.score2    = item.score_1;
          tmpItem.raceTo1   = item.race_to_2;
          tmpItem.raceTo2   = item.race_to_1;
          tmpItem.result    = (item.winner === 2) ? 'W' : '';
        }
        if (tmpItem.result !== 'W' && item.winner !== 0) {
          tmpItem.result = 'L';
        }
        let tmpViewDT = item.start_dt;
        if (tmpViewDT.substr(0, 10) === nowDT) {
          tmpItem.viewIcon = 'fa-clock-o';
          tmpViewDT = tmpViewDT.substr(11, 5);
        } else {
          tmpItem.viewIcon = 'fa-calendar';
          tmpViewDT = tmpViewDT.substr(5, 5);
        }
        tmpItem.viewDT = tmpViewDT;

        tmpItem.place = item.d_name;
        if (item.d_id !== 0 && item.d_tbl !== 0) {
          tmpItem.place += ' ' + item.d_tbl + '番台';
        }
        tmpItem.title = item.e_name;
        // checked window.console.log(item);
        tempList.push(tmpItem);
      }
      this.gameList = tempList;
      this.isLoading = false;
      return;
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }

  private getSoloScoreList(searchParam: {[key: string]: any}, pageNum: number) {
    // 検索情報を取得
    // ローカルから取得
    // 時刻確認
    // データ取得
    // 条件なしは保存する
    // const saveProfile: string|null = window.localStorage.getItem('score-list-9ball');
    searchParam.usr_id = this.$store.getters.getUsrId();
    searchParam.game_type = this.gameNum;
    searchParam.page_num = pageNum;
    const token = this.$store.getters.getToken();
    libAxios.get('game-data/solo', token, searchParam)
    .then((response) => {
      const tempList: any[] = [];
      const nowDT = this._formatDate(new Date(), 'Y-m-d');
      const usrID = this.$store.getters.getUsrId();
      for (const item of response.data) {
        const tmpItem: {[key: string]: any} = {};
        let tmpViewDT = item.start_dt;
        if (tmpViewDT.substr(0, 10) === nowDT) {
          tmpItem.viewIcon = 'fa-clock-o';
          tmpViewDT = tmpViewDT.substr(11, 5);
        } else {
          tmpItem.viewIcon = 'fa-calendar';
          tmpViewDT = tmpViewDT.substr(5, 5);
        }
        tmpItem.viewDT = tmpViewDT;
        tmpItem.score = item.score;
        tmpItem.place = item.d_name;
        if (item.d_id !== 0 && item.d_tbl !== 0) {
          tmpItem.place += ' ' + item.d_tbl + '番台';
        }
        tmpItem.title = item.e_name;
        tempList.push(tmpItem);
      }
      this.gameList = tempList;
      this.isLoading = false;
      return;
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }

  private getMultiScoreList(searchParam: {[key: string]: any}, pageNum: number) {
    // 検索情報を取得
    // ローカルから取得
    // 時刻確認
    // データ取得
    // 条件なしは保存する
    // const saveProfile: string|null = window.localStorage.getItem('score-list-9ball');
    searchParam.usr_id = this.$store.getters.getUsrId();
    searchParam.game_type = this.gameNum;
    searchParam.page_num = pageNum;
    const token = this.$store.getters.getToken();
    libAxios.get('game-data/multi', token, searchParam)
    .then((response) => {
      const tempList: any[] = [];
      const nowDT = this._formatDate(new Date(), 'Y-m-d');
      const usrID = this.$store.getters.getUsrId();
      for (const item of response.data) {
        const tmpItem: {[key: string]: any} = {};
        let tmpViewDT = item.start_dt;
        if (tmpViewDT.substr(0, 10) === nowDT) {
          tmpItem.viewIcon = 'fa-clock-o';
          tmpViewDT = tmpViewDT.substr(11, 5);
        } else {
          tmpItem.viewIcon = 'fa-calendar';
          tmpViewDT = tmpViewDT.substr(5, 5);
        }
        tmpItem.viewDT = tmpViewDT;
        tmpItem.score = item.score;
        tmpItem.place = item.d_name;
        if (item.d_id !== 0 && item.d_tbl !== 0) {
          tmpItem.place += ' ' + item.d_tbl + '番台';
        }
        tmpItem.title = item.e_name;
        tempList.push(tmpItem);
      }
      this.gameList = tempList;
      this.isLoading = false;
      return;
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }
}
