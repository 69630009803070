

































































import {Component, Vue} from 'vue-property-decorator';
import {Department} from '@/model/class/department';
import {User}       from '@/model/class/user';
import Header       from '@/components/common/Header.vue';
import libAxios     from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
  },
})
export default class Login extends Vue {
  private isLoading: boolean = true;
  private usrId: string = '';
  private password: string = '';
  private progress: boolean = false;
  private error: string = '';

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          this.$router.push({name: 'top'});
        } else {
          this.isLoading = false;
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private get requiredUsrId(): boolean {
    return (this.usrId === '');
  }

  private get requiredPassword(): boolean {
    return (this.password === '');
  }

  private resetError(): void {
    this.error = '';
  }

  private async login() {
    // this.isLoading = true;
    const postParam = {
      usr_id: this.usrId,
      usr_pwd: this.password,
    };
    return await libAxios.postNoToken('login', postParam)
    .then((param: {[key: string]: any}) => {
      const tmpDepts = [];
      for (const dept of param.departments) {
        const tmpUsrs = [];
        for (const usr of dept.users) {
          if (usr.id === param.usrId) {
            continue; // 自分自身は入れない
          }
          tmpUsrs.push(
            new User(
              usr.id,
              usr.name,
              usr.nameShort,
            ),
          );
        }
        tmpDepts.push(
          new Department(
            dept.id,
            dept.name,
            dept.numOfTable,
            dept.isUsePlace,
            tmpUsrs,
          ),
        );
      }

      param.departments = tmpDepts;
      this.$store.dispatch('loginAction', param);
      this.$router.push({name: 'top'});
    }).catch((e: Error) => {
      const tmpJson = JSON.parse(e.message);
      if (tmpJson.validate) {
        this.error = 'val err';
      } else if (tmpJson.message) {
        this.error = tmpJson.message;
      } else {
        this.$router.push({name: 'error'});
      }
    });
  }
}
