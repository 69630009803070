






























import {Component, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class PointConfig extends Vue {
  private config!: {[key: string]: number};
  private simpleVal = [
    {text: 'なし', value: 0},
    {text: 'あり', value: 1},
  ];

  private get _IsOpen(): number {
    return this.config.isOpen;
  }

  private set _IsOpen(value: number) {
    this.$store.dispatch('multi/setConfigParamAction', {key: 'isOpen', val: value});
  }

  private get _LimitInning(): number {
    return this.config.limitInning;
  }

  private set _LimitInning(value: number) {
    this.$store.dispatch('multi/setConfigParamAction', {key: 'limitInning', val: value});
  }

  private created(): void {
    this.config = this.$store.getters['multi/getConfig']();
  }
}
