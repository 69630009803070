


































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {PlaceParam}       from '@/model/interface/PlaceParam';
import {MultiPlayerParam} from '@/model/interface/MultiPlayerParam';
import {FreePointParam}   from '@/model/interface/FreePointParam';
import MultiHeader        from '@/components/score/multi/MultiHeader.vue';
import FreePointSetting   from '@/components/score/multi/FreePointSetting.vue';
import FreePointTable     from '@/components/score/multi/FreePointTable.vue';
import FreePointPanel     from '@/components/score/multi/FreePointPanel.vue';

import BootstrapVue  from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    MultiHeader,
    FreePointSetting,
    FreePointTable,
    FreePointPanel,
  },
})
export default class GameFreePoint extends Vue {
  private isLoading: boolean = true;
  private isSetting: boolean = true;
  private usrType!: number;
  private eventId!: number;

  private currentNum: number = 0;
  private playerData!: FreePointParam[];
  private playerLen!: number;
  private baseTime: number = 0;
  private baseTimeOrg: number = 0; // 再開時の元
  private place!: string;
  private notPost: boolean = true;

  private created(): void {
    try {
      this._checkLogin().
      then((result: boolean) => {
        this.usrType = this.$store.getters.getUsrType();
        this.eventId = this.$store.getters['multi/getEventId']();
        this._created();
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      window.console.log(e.message);
      this.$router.push({name: 'error'});
    }
  }

  private _created(): void {
    const suspend = this._getResumeData(this.C_NUM_FREE_POINT);
    this.$store.dispatch('multi/initAction');
    if (suspend === '') { // 初期化に進む
      this.initGame();
    } else {
      const tmpGameId = this.$store.getters['multi/getGameId']();
      if (tmpGameId !== 0) {
        if (tmpGameId !== suspend.gameId) {
          this.$router.push({name: 'error'}); // SYSTEM ERROR
        }
      } else { // suspendからすべてを復活させる
        this.$store.dispatch('multi/resumeAction', suspend);
      }
      const tmpPlace = this.$store.getters['multi/getPlace']();
      if (tmpPlace.id === 0) {
        this.place = '未指定';
      } else {
        this.place = tmpPlace.name + ' ' + tmpPlace.tableNum + '番台';
      }

      const tmpParam = this.$store.getters['multi/getMultiGameParam']();
      if (tmpParam === '') {
        return; // 何もしない
      }
      this.playerData = JSON.parse(tmpParam);
      this.playerLen = this.playerData.length;
      // 一人目でフレーム確認
      this.currentNum = this.playerData[0].points.length - 1;
      const dateTime = new Date();
      this.baseTime = Math.floor( dateTime.getTime() / 1000 );
      this.isSetting = false;
    }
    this.isLoading = false;
  }

  private initGame(): void {
    // Eventだと何も設定しない
    if (this.eventId === 0) {
      const players = [];
      const player1 = JSON.parse(this.C_PARAM_POINT_PLAYER);
      if (this.usrType === this.C_MODE_MEMBER) {
        player1.id = this.$store.getters.getUsrId();
        player1.name = this.$store.getters.getUsrName();
      } else {
        player1.name = 'Guest1';
      }
      players.push(player1);

      const player2 = JSON.parse(this.C_PARAM_POINT_PLAYER);
      player2.name = 'Guest2';
      players.push(player2);
      this.$store.dispatch('multi/setPlayersAction', players);

      const tmpConfig = this._getConfig(this.C_CFG_FREE_PARAM, this.C_NAME_FREE_POINT);
      this.$store.dispatch('multi/setConfigAction', tmpConfig);
    }
  }

  private startGame(Multiplayers: MultiPlayerParam[], placeData: PlaceParam): void {
    this.isLoading = true;
    this.playerData = [];
    this.playerLen =  Multiplayers.length;

    if (placeData.id === 0) {
      this.place = '未指定';
    } else {
      this.place = placeData.name + ' ' + placeData.tableNum + '番台';
    }

    if (this.eventId === 0) {
      this.notPost = true;
    } else {
      this.notPost = false;
    }
    let namesStr = '';
    for (const player of Multiplayers) {
      if (player.id !== 0) {
        this.notPost = false;
      }
      const tmpPlayer = {
        id: player.id,
        name: player.name,
        eventId: player.eventId,
        isFix: player.isFix,
        point: player.point,
        scores: [0],
        points: [0],
        times: [0],
        totalScore: 0,
      };
      namesStr += player.name + ',';
      this.playerData.push(tmpPlayer);
    }

    this.currentNum = 0;

    const dateTime = new Date();
    this.baseTime = Math.floor( dateTime.getTime() / 1000 );

    const paramStr = JSON.stringify(this.playerData);
    try {
      if (this.usrType === this.C_MODE_GUEST || this.notPost) {
        const startParam = {
          gameType: this.C_NUM_FREE_POINT,
          players: Multiplayers,
          startDateTime: this._formatDate(dateTime, ''),
          param: paramStr,
        };
        this.$store.dispatch('multi/startGuestAction', startParam);
        this.isSetting = false;
        this.isLoading = false;
      } else {
        const startParam = {
          gamePreId: 0,
          gameType: this.C_NUM_FREE_POINT,
          place: placeData,
          players: Multiplayers,
          names: namesStr,
          startDateTime: this._formatDate(dateTime, ''),
          param: paramStr,
        };
        this.$store.dispatch('multi/startAction', startParam)
        .then(() => {
          this.isSetting = false;
          this.isLoading = false;
        }).catch((e) => {
          window.console.log(e);
        });
      }
    } catch (e) {
      window.console.log(e.message);
      this.$router.push({name: 'error'});
    }
  }

  private nextFrame(times: number, index: number, point: number): void {
    const currentPoint = times * point;
    for (let i = 0; i < this.playerLen; i++) {
      if (i === index) {
        this.playerData[i].points[this.currentNum] = point;
        this.playerData[i].times[this.currentNum] = times;
      } else {
        this.playerData[i].points[this.currentNum] = -(currentPoint);
        this.playerData[i].times[this.currentNum] = 0;
      }

      if (this.currentNum === 0 ) {
        this.playerData[i].scores[this.currentNum] = this.playerData[i].point;
      } else {
        this.playerData[i].scores[this.currentNum] = this.playerData[i].scores[this.currentNum - 1];
      }
      if (i === index) {
        this.playerData[i].scores[this.currentNum] += currentPoint * (this.playerLen - 1);
      } else {
        this.playerData[i].scores[this.currentNum] += this.playerData[i].points[this.currentNum];
      }
      this.playerData[i].totalScore = this.playerData[i].scores[this.currentNum];
    }

    if (this.currentNum < 9) {
      for (let i = 0; i < this.playerLen; i++) {
        this.playerData[i].scores.push(0);
        this.playerData[i].points.push(0);
        this.playerData[i].times.push(0);
      }
    }
    this.currentNum++;

    const dateTime = new Date() ;
    const nowDate = Math.floor( dateTime.getTime() / 1000 ) ;

    const paramStr = JSON.stringify(this.playerData);
    const saveParam: {[key: string]: any} = {
      endDateTime: this._formatDate(dateTime, ''),
      playTimeSpan: this.baseTimeOrg + nowDate - this.baseTime,
      param: paramStr,
      inning: this.currentNum,
    };
    this.$store.dispatch('multi/saveAction', saveParam);
  }

  private toRestart(): void {
    if (!window.confirm('ここまでのデータを保存し設定画面に移動しますか？')) {
      return;
    }
    this.isLoading = true;
    this.isSetting = true;

    try {
      if (this.usrType === this.C_MODE_GUEST || this.notPost) {
        this.reStart();
      } else {
        const results = [];
        for (let i = 0; i < this.playerLen; i++) {
          if (this.playerData[i].id === 0) {
            continue;
          }
          if (this.currentNum === 0) {
            results.push({
              u_id: this.playerData[i].id,
              ep_id: this.playerData[i].eventId,
              // score: 0,
              score: this.playerData[i].point,
            });
          } else {
            results.push({
              u_id: this.playerData[i].id,
              ep_id: this.playerData[i].eventId,
              // score: this.playerData[i].scores[this.currentNum - 1],
              score: this.playerData[i].totalScore,
            });
          }
        }
        const finParam = {
          isFin: false,
          param: JSON.stringify(results),
          inning: this.currentNum,
        };
        this.$store.dispatch('multi/finAction', finParam)
        .then(() => {
          this.reStart();
        }).catch((e) => {
          throw new Error(e.message);
        });
      }
    } catch (e) {
      window.console.log(e.message);
      this.$router.push({name: 'error'});
    }
  }

  private reStart(): void {
    const players = [];
    for (const player of this.playerData) {
      const tmpPlayer = JSON.parse(this.C_PARAM_POINT_PLAYER);
      tmpPlayer.id = player.id;
      tmpPlayer.name = player.name;
      tmpPlayer.eventId = player.eventId;
      tmpPlayer.isFix = player.isFix;
      if (this.currentNum === 0) {
        tmpPlayer.point = player.point;
      } else {
        tmpPlayer.point = player.totalScore;
      }
      players.push(tmpPlayer);
    }

    this.$store.dispatch('multi/reStartAction', players);
    this.isSetting = true;
    this.isLoading = false;
  }

  private undo(): void {
    if (this.currentNum === 0) {
      return;
    }

    this.currentNum--;
    for (let i = 0; i < this.playerLen; i++) {
      this.playerData[i].points.pop();
      this.playerData[i].times.pop();
      this.playerData[i].scores.pop();
      this.playerData[i].scores[this.currentNum] = 0;
      if (this.currentNum === 0) {
        this.playerData[i].totalScore = this.playerData[i].point;
      } else {
        this.playerData[i].totalScore = this.playerData[i].scores[this.currentNum];
      }
    }
    window.console.log(this.playerData);
  }

// true: fin
  // false: continue
  private gameFin(isFinish: boolean) {
    this.isLoading = true;
    this.isSetting = true;
    try {
      if (this.usrType === this.C_MODE_GUEST || this.notPost) {
        if (isFinish) {
          this.$store.dispatch('multi/destroyAction');
          this.$router.push({name: 'score'});
        } else {
          this.nextGame();
        }
      } else {
        const results = [];
        for (let i = 0; i < this.playerLen; i++) {
          if (this.playerData[i].id === 0) {
            continue;
          }
          results.push({
            u_id: this.playerData[i].id,
            ep_id: this.playerData[i].eventId,
            score: this.playerData[i].totalScore,
          });
        }
        const finParam = {
          isFin: isFinish,
          param: JSON.stringify(results),
          inning: this.currentNum,
        };
        this.$store.dispatch('multi/finAction', finParam)
        .then(() => {
          if (this.eventId !== 0) {
            // TODO
            this.$router.push({ name: 'event-match', params: {type: this.C_NAME_MATCH} });
          } else {
            if (isFinish) {
              if (this.usrType === this.C_MODE_MEMBER) {
                // TODO
                // this.$router.push({name: 'score-multi-list', params: {type: this.C_NAME_FREE_POINT}});
                this.$router.push({name: 'score'});
              } else {
                this.$router.push({name: 'score'});
              }
            } else {
              this.nextGame();
            }
          }
        }).catch((e) => {
          throw new Error(e.message);
        });
      }
    } catch (e) {
      window.console.log(e.message);
      this.$router.push({name: 'error'});
    }
  }

  private nextGame(): void {
    const nextRandom = this.$store.getters['multi/getConfigParam']('nextRandom');
    if (nextRandom) {
      this.playerData = this._shuffle(this.playerData);
    }
    const nextPlayers = [];
    let namesStr = '';
    for (const player of this.playerData) {
      player.point = player.totalScore;
      player.scores = [0];
      player.points = [0];
      player.times = [0];
      player.totalScore = 0;
      namesStr += player.name + ',';

      const nextPlayer = JSON.parse(this.C_PARAM_POINT_PLAYER);
      nextPlayer.id = player.id;
      nextPlayer.name = player.name;
      nextPlayer.isFix = player.isFix;
      nextPlayer.point = player.totalScore;
      nextPlayers.push(nextPlayer);
    }

    this.currentNum = 0;

    const dateTime = new Date();
    this.baseTime = Math.floor( dateTime.getTime() / 1000 );

    const paramStr = JSON.stringify(this.playerData);
    try {
      if (this.usrType === this.C_MODE_GUEST || this.notPost) {
        const startParam = {
          gameType: this.C_NUM_FREE_POINT,
          players: nextPlayers,
          startDateTime: this._formatDate(dateTime, ''),
          param: paramStr,
        };
        this.$store.dispatch('multi/startGuestAction', startParam);
        this.isSetting = false;
        this.isLoading = false;
      } else {
        const startParam = {
          players: nextPlayers,
          startDateTime: this._formatDate(dateTime, ''),
          names: namesStr,
          param: paramStr,
        };
        this.$store.dispatch('multi/continueAction', startParam)
        .then(() => {
          this.isSetting = false;
          this.isLoading = false;
        }).catch((e) => {
          throw new Error(e.message);
        });
      }
    } catch (e) {
      window.console.log(e.message);
      this.$router.push({name: 'error'});
    }
  }

  private destroy(): void {
    if (window.confirm('ここまでのデータを破棄しますか？')) {
      this.$store.dispatch('multi/destroyAction');
      this.$router.push({name: 'score'});
    }
  }
}
