











































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class Game10BallResult extends Vue {
  @Prop() private gameParam!: {[key: string]: any};
  @Prop() private isEvent!: boolean;
  private maxLen: number = 0;
  private player1: string = '';
  private player2: string = '';
  private results1: number[] = [];
  private results2: number[] = [];
  private param: string = '';

  // 初期化
  private created(): void {
    let player1;
    let player2;
    const results1: number[] = [];
    const results2: number[] = [];
    let first;
    let result: string;

    if (this.isEvent) {
      const tmpPlayer1 = this.$store.getters['event/getPlayer'](this.gameParam.eventPlayerId1);
      player1 = tmpPlayer1.usrName;
      const tmpPlayer2 = this.$store.getters['event/getPlayer'](this.gameParam.eventPlayerId2);
      player2 = tmpPlayer2.usrName;
      first = (this.gameParam.first === 1);
      result = this.gameParam.result;
    } else {
      player1 = 'YOU';
      player2 = this.gameParam.plyerName;
      first = (this.gameParam.isFirst === 1);
      result = this.gameParam.results;
    }

    this.maxLen = result.length;
    for ( let i = 0; i < this.maxLen; i++) {
      const tmpChar = result.charAt(i);
      if (tmpChar === 'a') {
        results1[i] = 1;
        results2[i] = 0;
      } else if (tmpChar === 'A') {
        results1[i] = 2;
        results2[i] = 0;
      } else if (tmpChar === 'b') {
        results1[i] = 0;
        results2[i] = 1;
      } else if (tmpChar === 'B') {
        results1[i] = 0;
        results2[i] = 2;
      }
    }
    if (first) {
      this.player1 = player1;
      this.player2 = player2;
      this.results1 = results1;
      this.results2 = results2;
    } else {
      this.player1 = player2;
      this.player2 = player1;
      this.results1 = results2;
      this.results2 = results1;
    }
    this.param = this.gameParam.param;
  }

  private showDetail(inningNum: number) {
    window.console.log(inningNum);
  }
}
