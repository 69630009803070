

























































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import BootstrapVue      from 'bootstrap-vue';
import {EventEntryParam} from '@/model/interface/EventEntryParam';
Vue.use(BootstrapVue);

@Component
export default class EventEntry extends Vue {
  private importType: number = 0;
  private entries!: EventEntryParam[];
  private entryList: number[] = [];
  private entryAll: number[] = [];
  private isCheck: boolean = true;
  private msgCheck: string = '全チェック';
  private modalEntry: boolean = false;
  private usrName: string = '';
  private usrNameShort: string = '';
  private usrClass: string = '';
  private priUsrName: string = '';
  private priUsrNameShort: string = '';
  private priUsrClass: string = '';

  private options: any[] = [
    { text: '公式名', value: 0 },
    { text: '非公式名', value: 1 },
  ];

  @Emit('executeEvent') private executeEvent(param: {[key: string]: any}): void { return; }

  private get _Step0Status(): number {
    return this.$store.getters['event/getStepStatus'](0);
  }

  private created(): void {
    this.entries = this.$store.getters['event/getEntries']();
    for (const entry of this.entries) {
      this.entryAll.push(entry.replyId);
    }
  }

  private importEntry(): void {
    const param: {[key: string]: any} = {
      command: 'import',
      mode: this.importType,
      ids: this.entryList.join(','),
    };
    this.executeEvent(param);
  }

  private countEntry(): void {
    alert(this.entryList.length + '人です');
  }

  private checkToggle(): void {
    if (this.isCheck) {
      this.entryList = this.entryAll;
      this.isCheck = false;
      this.msgCheck = 'チェック解除';
    } else {
      this.entryList = [];
      this.isCheck = true;
      this.msgCheck = '全チェック';
    }
  }

  private showEntry(num: number): void {
    const currentEntry   = this.entries[num];
    this.usrName         = currentEntry.usrName;
    this.usrNameShort    = currentEntry.usrNameShort;
    this.usrClass        = currentEntry.usrClass;
    this.priUsrName      = currentEntry.priUsrName;
    this.priUsrNameShort = currentEntry.priUsrNameShort;
    this.priUsrClass     = currentEntry.priUsrClass;
    this.modalEntry      = true;
  }

  private funcDummy(): void {
    // 何もしない関数
  }

}
