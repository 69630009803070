
































import {Component, Prop, Emit, Vue} from 'vue-property-decorator';
import {EventGame}   from '@/model/class/EventGame';
import {EventPlayer} from '@/model/class/EventPlayer';
import BootstrapVue  from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class TournamentBox extends Vue {
  @Prop() private stepId!: number;
  @Prop() private setNum!: number;
  @Prop() private tnmId!: string;
  @Prop() private boxType!: string;
  private boxClass: string = '';
  private isMemoTop: boolean = false;
  private gameKey: string = '';
  private name1: string = '';
  private dept1: string = '';
  private name2: string = '';
  private dept2: string = '';
  private btnTitle: string = '';
  private bgColor: string = '';
  private p1Class: string = '';
  private p2Class: string = '';
  private useMatch: boolean = false;

  @Emit('viewGame') private _ViewGame(key: string): void { return; }

  private created(): void {
    // design
    if (this.boxType === 'base') {
      this.boxClass = 'box-base';
      this.isMemoTop = false;
      this.p1Class = 'player-win-side';
      this.p2Class = 'player-win-side';
    } else if (this.boxType === 'lose') {
      this.boxClass = 'box-loes-side';
      this.isMemoTop = true;
      this.p1Class = 'player-lose-side';
      this.p2Class = 'player-lose-side';
    }
    this.gameKey = this.stepId + '_' + this.setNum + this.tnmId;
    const tmpGame: EventGame = this.$store.getters['event/getGame'](this.gameKey);
    if (tmpGame.useMatch) {
      this.useMatch = false;
    } else {
      this.useMatch = true;
    }

    if (tmpGame.eventPlayerId1 !== 0) {
      const player1: EventPlayer = this.$store.getters['event/getPlayer'](tmpGame.eventPlayerId1);
      this.name1 = player1.usrName;
      this.dept1 = player1.deptName;
    } else {
      if (tmpGame.eventGameStatus >= this.C_GAME_STATUS_END) {
        this.name1 = 'Bye';
        this.dept1 = '';
      }
    }
    if (tmpGame.eventPlayerId2 !== 0) {
      const player2 = this.$store.getters['event/getPlayer'](tmpGame.eventPlayerId2);
      this.name2 = player2.usrName;
      this.dept2 = player2.deptName;
    } else {
      if (tmpGame.eventGameStatus >= this.C_GAME_STATUS_END) {
        this.name2 = 'Bye';
        this.dept2 = '';
      }
    }

    switch (tmpGame.eventGameStatus) {
      case this.C_GAME_STATUS_INIT:
      case this.C_GAME_STATUS_PAIR:
        this.btnTitle = '準備中';
        break;
      case this.C_GAME_STATUS_READY:
        if (tmpGame.refereeId === 0) {
          this.btnTitle = 'スタンバイ';
          this.bgColor = 'bg-stripe-9cf';
        } else {
          this.btnTitle = '呼出中';
          this.btnTitle = tmpGame.editDateTime;
          // 時間を確認10分以内が黄色
        }
        break;
      case this.C_GAME_STATUS_PLAY:
        if (tmpGame.refereeId === -1) {
          this.btnTitle = tmpGame.editDateTime.substr(10, 10);
          this.bgColor = 'bg-color-9f9';
        } else {
          this.btnTitle = tmpGame.editDateTime.substr(10, 10);
          this.bgColor = 'bg-color-9cf';
        }
        break;
      case this.C_GAME_STATUS_END:
      case this.C_GAME_STATUS_FIX:
        this.btnTitle = '終了';
        if (tmpGame.winner === 1 || tmpGame.winner === 3) {
          this.bgColor = '';
          this.p2Class += ' bg-color-999';
        } else {
          this.bgColor = '';
          this.p1Class += ' bg-color-999';
        }
        break;
      default:
        break;
    }
  }

  private viewGame(): void {
    if (!this.useMatch) {
      this._ViewGame(this.gameKey);
    } else {
      alert('データがありません');
    }
  }
}
