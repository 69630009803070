



















import {Component, Prop, Vue} from 'vue-property-decorator';
import AdminHeader  from '@/components/admin/common/AdminHeader.vue';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    AdminHeader,
  },
})
export default class AdminTop extends Vue {
  private usrType: number = this.$store.getters.getUsrType();
  private usrRole: number = this.$store.getters.getUsrRole();
  private isLoading: boolean = true;
  private rolesFlg: {canEditInfo: boolean, canEditMsg: boolean, canEditEvent: boolean, canEditAdmin: boolean}
    = this.$store.getters.getRolesFlg();
  private usrRoles: any[] = this.$store.getters.getRoles();

  private viewOrgBtn: boolean = false;
  private viewDeptBtn: boolean = false;
  private viewUsrBtn: boolean = false;
  private viewRoleBtn: boolean = false;

  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Event Admin', active: true},
  ];

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          if (!this.rolesFlg.canEditAdmin) {
            this.$router.push({name: 'error'});
          }

          switch (this.usrRole) {
            case this.C_ROLE_SYSTEM:
            case this.C_ROLE_ADMIN:
              this.viewOrgBtn = true;
              this.viewDeptBtn = true;
              this.viewUsrBtn = true;
              this.viewRoleBtn = true;
              break;
            case this.C_ROLE_MANAGER:
              this.viewUsrBtn = true;
            case this.C_ROLE_STAFF:
            case this.C_ROLE_MEMBER:
              for (const role of this.usrRoles) {
                if (this._bitCalc(role.r_type, 4)) {
                  if (role.ref_type === this.C_REF_ORG) {
                    this.viewDeptBtn = true;
                    this.viewUsrBtn = true;
                  } else if (role.ref_type === this.C_REF_DEPT) {
                    this.viewUsrBtn = true;
                  }
                }
              }
              break;
            default:
              break;
          }
          this.isLoading = false;
        } else {
          this.$router.push({name: 'top'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }
}
