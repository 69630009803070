






















import {Component, Vue} from 'vue-property-decorator';
import BootstrapVue     from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class AdminHeader extends Vue {
  private get usrName(): string {
    return this.$store.getters.getUsrName();
  }
  private get usrType(): number {
    return this.$store.getters.getUsrType();
  }
}
