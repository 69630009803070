











































































// 1:通知なし2:開封通知3:Yes-No4:択一選択肢5:複数選択
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import libAxios      from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component
export default class MessageDetail extends Vue {
  @Prop() private currentMsg!: {[key: string]: any};
  private isLoading: boolean = true;
  private imagePath: string = '';
  private imageCaption: string = '';
  private bodyHtml: string = '';
  private options: Array<{value: number, text: string}> = [];
  private answer: number = 0;
  private answers: number[] = [];

  @Emit('refreshView') private refreshView(data: {[key: string]: any}, isRefresh: boolean): void { return; }

  private created(): void {
    this.imagePath = this.currentMsg.img_path;
    this.imageCaption = this.currentMsg.img_text;
    this.bodyHtml = this.currentMsg.body;
    // entryでmsg有効の場合
    let optionsStr = this.currentMsg.options;
    this.answer = parseInt(this.currentMsg.reply_num, 10);
    if (this.currentMsg.m_type === 3 || optionsStr === '') {
        optionsStr = 'Yes,No';
    }
    const optionsBuf = optionsStr.split(',');
    this.answers = [];
    for (let i = 0; i < optionsBuf.length; i++) {
      this.options.push({value: (i + 1), text: optionsBuf[i]});
      const tmpNum = Math.pow(2, i); // (2 ** i)の表記をuglifyが認識しない
      if (this._bitCalc(this.answer, tmpNum)) {
        this.answers.push(i + 1);
      }
    }
    this.isLoading = false;
  }

  private reply(num: number) {
    this.answer = num;
    this.sendReply(num);
  }

  private replyAnswer() {
    if (this.answer === 0) {
      alert('選択してください');
      return;
    }
    this.sendReply(this.answer);
  }

  private replyAnswers() {
    const sumAns = this.answers.reduce( (a, b) => a + b);
    if (sumAns === 0) {
      alert('選択してください');
      return;
    }
    this.sendReply(sumAns);
  }

  private async sendReply(ans: number) {
    this.isLoading = true;
    const postParam = {
      reply_num: ans,
      edit_dt: this.currentMsg.edit_dt,
    };

    const token = this.$store.getters.getToken();
    return await libAxios.put('reply/' + this.currentMsg.mr_id, token, postParam)
    .then((param: {[key: string]: any}) => {
      this.isLoading = false;
    }).catch((e: Error) => {
      this.$router.push({name: 'error'});
    });
  }

  private async hideReply(ans: number) {
    const postParam = {
      hide_flg: true,
      edit_dt: this.currentMsg.edit_dt,
    };

    const token = this.$store.getters.getToken();
    return await libAxios.put('reply/' + this.currentMsg.mr_id, token, postParam)
    .then((param: {[key: string]: any}) => {
      this.refreshView({}, true);
    }).catch((e: Error) => {
      this.$router.push({name: 'error'});
    });
  }

}
