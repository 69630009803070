






































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {PlaceParam}       from '@/model/interface/PlaceParam';
import {MultiPlayerParam} from '@/model/interface/MultiPlayerParam';
import {PointPlayerParam} from '@/model/interface/PointPlayerParam';
import MultiHeader        from '@/components/score/multi/MultiHeader.vue';
import PointSetting       from '@/components/score/multi/PointSetting.vue';
import PointTable         from '@/components/score/multi/PointTable.vue';
import PointPanel         from '@/components/score/multi/PointPanel.vue';

import BootstrapVue  from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    MultiHeader,
    PointSetting,
    PointTable,
    PointPanel,
  },
})
export default class GamePoint extends Vue {
  private isLoading: boolean = true;
  private isSetting: boolean = true;
  private usrType!: number;
  private eventId!: number;

  private currentNum: number = 0;
  private playerData!: PointPlayerParam[];
  private playerLen!: number;
  private baseTime: number = 0;
  private baseTimeOrg: number = 0; // 再開時の元
  private place!: string;
  private frameData: Array<{breakNum: number, isDouble: boolean, isRunout: boolean}> = [];
  private notPost: boolean = true;

  private created(): void {
    try {
      this._checkLogin().
      then((result: boolean) => {
        this.usrType = this.$store.getters.getUsrType();
        this.eventId = this.$store.getters['multi/getEventId']();
        this._created();
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      window.console.log(e.message);
      this.$router.push({name: 'error'});
    }
  }

  private _created(): void {
    const suspend = this._getResumeData(this.C_NUM_POINT);
    this.$store.dispatch('multi/initAction');
    if (suspend === '') { // 初期化に進む
      this.initGame();
    } else {
      const tmpGameId = this.$store.getters['multi/getGameId']();
      if (tmpGameId !== 0) {
        if (tmpGameId !== suspend.gameId) {
          this.$router.push({name: 'error'}); // SYSTEM ERROR
        }
      } else { // suspendからすべてを復活させる
        this.$store.dispatch('multi/resumeAction', suspend);
      }
      const tmpParam = this.$store.getters['multi/getMultiGameParam']();
      if (tmpParam === '') {
        return; // 何もしない
      }
      const buffer = tmpParam.split('_');
      this.playerData = JSON.parse(buffer[0]);
      this.playerLen = this.playerData.length;
      this.frameData = JSON.parse(buffer[1]);

      this.currentNum = this.frameData.length - 1;
      const dateTime = new Date();
      this.baseTime = Math.floor( dateTime.getTime() / 1000 );
      this.isSetting = false;
    }
    this.isLoading = false;
  }

  private initGame(): void {
    // Eventだと何も設定しない
    if (this.eventId === 0) {
      const players = [];
      const player1 = JSON.parse(this.C_PARAM_POINT_PLAYER);
      if (this.usrType === this.C_MODE_MEMBER) {
        player1.id = this.$store.getters.getUsrId();
        player1.name = this.$store.getters.getUsrName();
      } else {
        player1.name = 'Guest1';
      }
      players.push(player1);

      const player2 = JSON.parse(this.C_PARAM_POINT_PLAYER);
      player2.name = 'Guest2';
      players.push(player2);
      this.$store.dispatch('multi/setPlayersAction', players);

      const tmpConfig = this._getConfig(this.C_CFG_POINT_PARAM, this.C_NAME_POINT);
      this.$store.dispatch('multi/setConfigAction', tmpConfig);
    }
  }

  private startGame(Multiplayers: MultiPlayerParam[], placeData: PlaceParam): void {
    this.isLoading = true;
    this.playerData = [];
    this.playerLen =  Multiplayers.length;

    if (placeData.id === 0) {
      this.place = '未指定';
    } else {
      this.place = placeData.name + ' ' + placeData.tableNum + '番台';
    }

    if (this.eventId === 0) {
      this.notPost = true;
    } else {
      this.notPost = false;
    }
    let namesStr = '';
    for (const player of Multiplayers) {
      if (player.id !== 0) {
        this.notPost = false;
      }
      const tmpPlayer = {
        id: player.id,
        name: player.name,
        eventId: player.eventId,
        isFix: player.isFix,
        handicap: player.handicap,
        etc: player.etc,
        point: player.point,
        scores: [0],
        totalScore: 0,
        points: [[0, 0, 0]],
        sumPoint: [0],
        tmpScore: 0,
        tmpPoint: [0, 0, 0],
      };
      namesStr += player.name + ',';
      this.playerData.push(tmpPlayer);
    }

    this.frameData = [];
    this.frameData.push({breakNum: 0, isDouble: false, isRunout: false});
    this.currentNum = 0;

    const dateTime = new Date();
    this.baseTime = Math.floor( dateTime.getTime() / 1000 );

    const paramStr = JSON.stringify(this.playerData) + '_' + JSON.stringify(this.frameData);
    try {
      if (this.usrType === this.C_MODE_GUEST || this.notPost) {
        const startParam = {
          gameType: this.C_NUM_POINT,
          players: Multiplayers,
          startDateTime: this._formatDate(dateTime, ''),
          param: paramStr,
        };
        this.$store.dispatch('multi/startGuestAction', startParam);
        this.isSetting = false;
        this.isLoading = false;
      } else {
        const startParam = {
          gamePreId: 0,
          gameType: this.C_NUM_POINT,
          place: placeData,
          players: Multiplayers,
          names: namesStr,
          startDateTime: this._formatDate(dateTime, ''),
          param: paramStr,
        };
        this.$store.dispatch('multi/startAction', startParam)
        .then(() => {
          this.isSetting = false;
          this.isLoading = false;
        }).catch((e) => {
          window.console.log(e);
        });
      }
    } catch (e) {
      window.console.log(e.message);
      this.$router.push({name: 'error'});
    }
  }

  private updatePoint(num: number, index: number): void {
    this.playerData[index].tmpPoint[num] += 1;

    let tmpPoint = this.playerData[index].tmpPoint[0];
    tmpPoint += this.playerData[index].tmpPoint[1] * 2;
    tmpPoint += this.playerData[index].tmpPoint[2] * 4;
    this.playerData[index].tmpScore = tmpPoint;
  }

  private clearPoint(index: number): void {
    this.playerData[index].tmpPoint = [0, 0, 0];
    this.playerData[index].tmpScore = 0;
  }

  private nextFrame(index: number, runout: boolean): void {
    let isEven = true;
    this.frameData[this.currentNum].isRunout = runout;
    for (let i = 0; i < this.playerLen; i++) {
      let tmpSumScore = this.playerData[i].tmpScore * (this.playerLen - 1);
      for (let j = 0; j < this.playerLen; j++) {
        if (i === j) {
          continue;
        }
        tmpSumScore -= this.playerData[j].tmpScore;
      }
      if (tmpSumScore !== 0) {
        isEven = false;
        if (runout) {
          tmpSumScore *= 2;
        }
        // チャラ倍
        const useDouble = this.$store.getters['multi/getConfigParam']('useDouble');
        if (this.frameData[this.currentNum].isDouble && useDouble) {
          tmpSumScore *= 2;
        }
      }
      //     // this.$set(this.playerData[index].tmpPoint, num, tmpNum);
      this.playerData[i].sumPoint[this.currentNum] = tmpSumScore;
      this.playerData[i].points[this.currentNum] = this.playerData[i].tmpPoint;
      if (this.currentNum === 0 ) {
        this.playerData[i].scores[this.currentNum] = tmpSumScore + this.playerData[i].point;
      } else {
        this.playerData[i].scores[this.currentNum] = tmpSumScore + this.playerData[i].scores[this.currentNum - 1];
      }
      this.playerData[i].totalScore = this.playerData[i].scores[this.currentNum];
    }
    if (this.currentNum < 9) {
      for (let i = 0; i < this.playerLen; i++) {
        this.playerData[i].points.push([0, 0, 0]);
        this.playerData[i].sumPoint.push(0);
        this.playerData[i].scores.push(0);
        this.playerData[i].tmpScore = 0;
        this.playerData[i].tmpPoint = [0, 0, 0];
      }
    }
    // 次のゲームのために保存
    this.frameData.push({breakNum: index, isDouble: isEven, isRunout: false});
    this.currentNum++;

    const dateTime = new Date() ;
    const nowDate = Math.floor( dateTime.getTime() / 1000 ) ;

    const paramStr = JSON.stringify(this.playerData) + '_' + JSON.stringify(this.frameData);
    const saveParam: {[key: string]: any} = {
      endDateTime: this._formatDate(dateTime, ''),
      playTimeSpan: this.baseTimeOrg + nowDate - this.baseTime,
      param: paramStr,
      inning: this.currentNum,
    };
    this.$store.dispatch('multi/saveAction', saveParam);
  }

  private undo(): void {
    if (this.currentNum === 0) {
      return;
    }

    this.currentNum--;
    for (let i = 0; i < this.playerLen; i++) {
      this.playerData[i].tmpPoint = this.playerData[i].points[this.currentNum];
      let tmpPoint = this.playerData[i].tmpPoint[0];
      tmpPoint += this.playerData[i].tmpPoint[1] * 2;
      tmpPoint += this.playerData[i].tmpPoint[2] * 4;
      this.playerData[i].tmpScore = tmpPoint;
      this.playerData[i].points.pop();
      this.playerData[i].sumPoint.pop();
      this.playerData[i].scores.pop();
    }
    this.frameData.pop();
  }

  // true: fin
  // false: continue
  private gameFin(isFinish: boolean) {
    this.isLoading = true;
    this.isSetting = true;
    try {
      if (this.usrType === this.C_MODE_GUEST || this.notPost) {
        if (isFinish) {
          this.$store.dispatch('multi/destroyAction');
          this.$router.push({name: 'score'});
        } else {
          this.nextGame();
        }
      } else {
        const results = [];
        for (let i = 0; i < this.playerLen; i++) {
          if (this.playerData[i].id === 0) {
            continue;
          }
          results.push({
            u_id: this.playerData[i].id,
            ep_id: this.playerData[i].eventId,
            score: this.playerData[i].totalScore,
          });
        }
        const finParam = {
          isFin: isFinish,
          param: JSON.stringify(results),
          inning: this.currentNum,
        };
        this.$store.dispatch('multi/finAction', finParam)
        .then(() => {
          if (this.eventId !== 0) {
            // TODO
            this.$router.push({ name: 'event-match', params: {type: this.C_NAME_MATCH} });
          } else {
            if (isFinish) {
              if (this.usrType === this.C_MODE_MEMBER) {
                // TODO
                // this.$router.push({name: 'score-multi-list', params: {type: this.C_NAME_POINT}});
                this.$router.push({name: 'score'});
              } else {
                this.$router.push({name: 'score'});
              }
            } else {
              this.nextGame();
            }
          }
        }).catch((e) => {
          throw new Error(e.message);
        });
      }
    } catch (e) {
      window.console.log(e.message);
      this.$router.push({name: 'error'});
    }
  }

// continue game
  private nextGame(): void {
    let firstNum = this.frameData[10].breakNum; // 次のフレームなので10番目
    const isEven = this.frameData[10].isDouble; // 次のフレームなので10番目
    let tmpPlayer = [];
    const nextRandom = this.$store.getters['multi/getConfigParam']('nextRandom');
    if (this.playerLen >= 4 && nextRandom) {
      const tmpFirst = this.playerData[firstNum];
      for (let i = 0; i < this.playerLen; i++) {
        if (i !== firstNum) {
          tmpPlayer.push(this.playerData[i]);
        }
      }
      tmpPlayer = this._shuffle(tmpPlayer);
      tmpPlayer.unshift(tmpFirst);
    } else {
      for (let i = 0; i < this.playerLen; i++) {
        tmpPlayer.push(this.playerData[firstNum]);
        firstNum--;
        if (firstNum < 0) {
          firstNum += this.playerLen;
        }
      }
    }
    const nextPlayers = [];
    let namesStr = '';
    for (const player of tmpPlayer) {
      player.point = player.totalScore;
      player.scores = [0];
      player.totalScore = 0;
      player.points = [[0, 0, 0]];
      player.sumPoint = [0];
      player.tmpScore = 0;
      player.tmpPoint = [0, 0, 0];
      namesStr += player.name + ',';

      const nextPlayer = JSON.parse(this.C_PARAM_POINT_PLAYER);
      nextPlayer.id = player.id;
      nextPlayer.name = player.name;
      nextPlayer.isFix = player.isFix;
      nextPlayer.handicap = player.handicap;
      nextPlayer.etc = player.etc;
      nextPlayer.point = player.totalScore;
      nextPlayers.push(nextPlayer);
    }
    this.playerData = tmpPlayer;

    this.frameData = [];
    this.frameData.push({breakNum: 0, isDouble: isEven, isRunout: false});
    this.currentNum = 0;

    const dateTime = new Date();
    this.baseTime = Math.floor( dateTime.getTime() / 1000 );

    const paramStr = JSON.stringify(this.playerData) + '_' + JSON.stringify(this.frameData);
    try {
      if (this.usrType === this.C_MODE_GUEST || this.notPost) {
        const startParam = {
          gameType: this.C_NUM_POINT,
          players: nextPlayers,
          startDateTime: this._formatDate(dateTime, ''),
          param: paramStr,
        };
        this.$store.dispatch('multi/startGuestAction', startParam);
        this.isSetting = false;
        this.isLoading = false;
      } else {
        const startParam = {
          players: nextPlayers,
          startDateTime: this._formatDate(dateTime, ''),
          names: namesStr,
          param: paramStr,
        };
        this.$store.dispatch('multi/continueAction', startParam)
        .then(() => {
          this.isSetting = false;
          this.isLoading = false;
        }).catch((e) => {
          throw new Error(e.message);
        });
      }
    } catch (e) {
      window.console.log(e.message);
      this.$router.push({name: 'error'});
    }
  }

  private toRestart(): void {
    if (!window.confirm('ここまでのデータを保存し設定画面に移動しますか？')) {
      return;
    }
    this.isLoading = true;
    this.isSetting = true;
    try {
      if (this.usrType === this.C_MODE_GUEST || this.notPost) {
        this.reStart();
      } else {
        const results = [];
        for (let i = 0; i < this.playerLen; i++) {
          if (this.playerData[i].id === 0) {
            continue;
          }
          results.push({
            u_id: this.playerData[i].id,
            ep_id: this.playerData[i].eventId,
            score: this.playerData[i].totalScore,
          });
        }
        const finParam = {
          isFin: false,
          param: JSON.stringify(results),
          inning: this.currentNum,
        };
        this.$store.dispatch('multi/finAction', finParam)
        .then(() => {
          this.reStart();
        }).catch((e) => {
          throw new Error(e.message);
        });
      }
    } catch (e) {
      window.console.log(e.message);
      this.$router.push({name: 'error'});
    }
  }

  private reStart(): void {
    const players = [];
    for (const player of this.playerData) {
      const tmpPlayer = JSON.parse(this.C_PARAM_POINT_PLAYER);
      tmpPlayer.id = player.id;
      tmpPlayer.name = player.name;
      tmpPlayer.eventId = player.eventId;
      tmpPlayer.isFix = player.isFix;
      tmpPlayer.handicap = player.handicap;
      tmpPlayer.etc = player.etc;
      if (this.currentNum === 0) {
        tmpPlayer.point = player.point;
      } else {
        tmpPlayer.point = player.totalScore;
      }
      players.push(tmpPlayer);
    }

    this.$store.dispatch('multi/reStartAction', players);
    this.isSetting = true;
    this.isLoading = false;
  }

  private destroy(): void {
    if (window.confirm('ここまでのデータを破棄しますか？')) {
      this.$store.dispatch('multi/destroyAction');
      this.$router.push({name: 'score'});
    }
  }
}
