































import {Component, Vue} from 'vue-property-decorator';
import Header           from '@/components/common/Header.vue'; // @ is an alias to /src
// import LibCommon from '@/mixin/LibCommon.ts'; 実装方法を残すためコメントアウト
import libAxios        from '@/mixin/LibAxios';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  // mixins: [LibCommon],実装方法を残すためコメントアウト
  components: {
    Header,
  },
})
export default class Password extends Vue {
  private isLoading: boolean = true;
  private pwd0: string = '';
  private pwd1: string = '';
  private pwd2: string = '';
  private isSend: boolean = false;

  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', to: { name: 'top' }},
    { text: '個人メニュー', to: { name: 'personal-menu' }},
    { text: 'パスワード変更', active: true},
  ];

  private get pwd0State(): boolean {
    return this.pwd0.length > 0 ? true : false;
  }

  private get pwd1State(): boolean {
    return this.pwd1.length > 3 ? true : false;
  }

  private get pwd2State(): boolean {
    return (this.pwd2.length > 3 && this.pwd1 === this.pwd2) ? true : false;
  }

  private get canSend(): boolean {
    return (this.pwd0State && this.pwd1State && this.pwd2State);
  }

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (!isLogin) {
          this.$router.push({name: 'top'});
        }
        this.isLoading = false;
      }).catch((e: Error) => {
        this.$router.push({name: 'error'});
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private save() {
    const postParam: {[key: string]: any} = {
      password_now: this.pwd0,
      password_new: this.pwd1,
    };
    this.chengePassword(postParam);
  }

  private async chengePassword(param: {[key: string]: any}) {
    const token = this.$store.getters.getToken();
    return await libAxios.put('password', token, param)
    .then((data: {[key: string]: any}) => {
      alert('更新しました');

    }).catch((e: Error) => {
      // throw new Error(e.message);
    });
  }
}
