
























































































































































































































































































import {Component, Watch, Vue} from 'vue-property-decorator';
import EventHeader    from '@/components/event-admin/common/EventHeader.vue';
import libAxios       from '@/mixin/LibAxios';
import BootstrapVue   from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    EventHeader,
  },
})
export default class EARegularDeteil extends Vue {
  private isLoading: boolean = true;
  private usrType!: number;
  private regularId: number = 0;
  private configList: Array<{[text: string]: any}> = [];
  private tabIndex: number = 0;
  private editDateTime!: string;

  private reName: string = '';
  private reListScorp: number = 0;
  private reListLead: string = '';

  private reType: number = 0;
  private reStatus: number = 0; // ステータスに依存
  private reStatusFlg: boolean = false;
  private reMode1: boolean = false;
  private reMode2: boolean = false;
  private reMode3: boolean = false;
  private reFirstTime: string = '';
  private reDuration: number = 1;
  private reOpenDay: number = 10;
  private reOpenTime: string = '';
  private reCloseDay: number = 10;
  private reCloseTime: string = '';
  private reEntryDay: number = 10;
  private reEntryTime: string = '';
  private reEntryEndDay: number = 10;
  private reEntryEndTime: string = '';
  private reCondCls: string = '';
  private reCondSex: number = 0;
  private reCondAge: number = 0;
  private reCondLim: number = 0;
  private reCondWait: number = 0;
  private reEntryMsg: string = '';
  private reEntryOpt: string = '';
  private reEntryScorp: number = 0;
  private reTimes: number = 0;
  private reTitle: string = '';
  private reSummary: string = '';
  private reBody: string = '';
  private reImgPath: string = '';
  private reImgText: string = '';
  private reHp: string = '';
  private reFb: string = '';
  private reConfigs: number[] = [];
  private reRefPlace1: boolean = false;
  private reRefPlace2: boolean = false;
  private reRefPlace3: boolean = false;
  private reRefPlace4: boolean = false;
  private valReRefPlace1: boolean = false;
  private valReRefPlace2: boolean = false;
  private valReRefPlace3: boolean = false;
  private valReRefPlace4: boolean = false;

  private reCycle: number = 0; // 0:手動 1:weekly 2:month(週と曜日) 3:month(日)',
  private reTgtDay: number = 0;
  private reTgtWeek: number = 0;
  private reTgtDate: number = 0;
  // msg使用時
  private reToType1: boolean = false;
  private reToType2: boolean = false;
  private reToType3: boolean = false;
  private reToType4: boolean = false;
  private valReToType1: boolean = false;
  private valReToType2: boolean = false;
  private valReToType3: boolean = false;
  private valReToType4: boolean = false;

  // 選択した権限で場所が決まる。
  private reFromId: number = -1;
  private reFromName: string = '';
  private reFromUrl: string = '';
  private reCategory: number = 0;
  private reFromData: Array<{[key: string]: any}> = [];

  private breadItems: Array<{[key: string]: any}> = [
    { text: 'イベントTop', to: { name: 'event-admin-top' }},
    { text: '定例一覧', to: { name: 'event-admin-regular'}},
  ];

  @Watch('reFromId')
  private onChangeReFrom(newValue: number, oldValue: number): void {
    const target = this.reFromData.find((item) => {
      return (item.value === newValue);
    });
    if (target) {
      this.reFromName = target.ref_name;
      this.valReRefPlace1 = this.valReToType1 = this._bitCalc(target.target, 1);
      if (!this.valReToType1 && this.reToType1) {
        this.reToType1 = false;
      }
      if (!this.valReRefPlace1 && this.reRefPlace1) {
        this.reRefPlace1 = false;
      }
      this.valReRefPlace2 = this.valReToType2 = this._bitCalc(target.target, 2);
      if (!this.valReToType2 && this.reToType2) {
        this.reToType2 = false;
      }
      if (!this.valReRefPlace2 && this.reRefPlace2) {
        this.reRefPlace2 = false;
      }
      this.valReRefPlace3 = this.valReToType3 = this._bitCalc(target.target, 3);
      if (!this.valReToType3 && this.reToType3) {
        this.reToType3 = false;
      }
      if (!this.valReRefPlace3 && this.reRefPlace3) {
        this.reRefPlace3 = false;
      }
      this.valReRefPlace4 = this.valReToType4 = this._bitCalc(target.target, 4);
      if (!this.valReToType4 && this.reToType4) {
        this.reToType4 = false;
      }
      if (!this.valReRefPlace4 && this.reRefPlace4) {
        this.reRefPlace4 = false;
      }
    }
  }

  private get reNameStatus(): boolean {
    return (this.reName.length > 0 && this.reName.length <= 64) ? true : false;
  }

  private get reFromIdStatus(): boolean {
    return (this.reFromId !== -1) ? true : false;
  }

  private created(): void {
    try {
      this._checkLogin().
      then((isLogin: boolean) => {
        if (isLogin) {
          this.usrType = this.$store.getters.getUsrType();
          // r_id=0
          // ロールの確認
          const roles = this.$store.getters.getRoles();
          for (const item of roles) {
            this.reFromData.push({
              text: item.r_name,
              value: item.r_id,
              target: item.r_target,
              ref_name: item.ref_name,
              ref_id: item.ref_id,
              ref_pref: item.ref_pref,
              ref_type: item.ref_type,
            });
          }

          this.getConfigList();
          this.regularId = parseInt(this.$route.params.id, 10);
          if (this.regularId === 0) {
            this.breadItems.push({ text: '定例新規作成', active: true});
            this.isLoading = false;
          } else {
            this.getRegular();
          }
        } else {
          this.$router.push({name: 'top'});
        }
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }

  private getConfigList() {
    this.isLoading = true;
    const token = this.$store.getters.getToken();
    libAxios.get('event-config', token, {})
    .then((response) => {
      for (const item of response.data) {
        this.configList.push({ text: item.ec_title, value: item.ec_id});
      }
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }

  private getRegular() {
    const token = this.$store.getters.getToken();
    libAxios.get('regular-data/' + this.regularId + '/edit', token, {})
    .then((param: {[key: string]: any}) => {
      this.reName      = param.re_name;
      this.reStatus    = param.re_status;
      if (this.reStatus === this.C_COMMON_VALID) {
        this.reStatusFlg = true;
      }
      this.reListLead  = param.re_list_lead;
      this.reListScorp = param.re_list_scorp;
      this.reType      = param.re_type;
      this.reMode1     = this._bitCalc(param.re_mode, 1);
      this.reMode2     = this._bitCalc(param.re_mode, 2);
      this.reMode3     = this._bitCalc(param.re_mode, 3);
      this.reCategory  = param.re_category;
      this.reCycle     = param.re_cycle;
      this.reTgtDay    = param.re_tgt_day;
      this.reTgtWeek   = param.re_tgt_week;
      this.reTgtDate   = param.re_tgt_date;
      this.reFirstTime = param.re_first_t;
      this.reDuration  = param.re_duration;
      this.reOpenDay   = param.re_open_d;
      this.reOpenTime  = param.re_open_t;
      this.reCloseDay  = param.re_close_d;
      this.reCloseTime = param.re_close_t;

      this.reToType1  = this._bitCalc(param.re_to_type, 1);
      this.reToType2  = this._bitCalc(param.re_to_type, 2);
      this.reToType3  = this._bitCalc(param.re_to_type, 3);
      this.reToType4  = this._bitCalc(param.re_to_type, 4);

      if (param.from_type === 1) {
        this.reFromId   = 0;
      } else {
        this.reFromId   = param.re_from_id;
      }
      this.reFromName = param.re_from_name;
      this.reFromUrl  = param.re_from_url;

      this.reTimes  = param.re_times;
      this.reTitle  = param.re_title;

      this.reBody     = param.re_body;
      this.reSummary  = param.re_summary;
      this.reImgPath  = param.re_img_path;
      this.reImgText  = param.re_img_text;
      this.reFb  = param.re_fb;
      this.reHp  = param.re_hp;

      // 試合情報
      const tmpConfigs = param.re_configs.split(',');
      for (const tmpConfig of tmpConfigs) {
        this.reConfigs.push(parseInt(tmpConfig, 10));
      }
      this.reRefPlace1 = this._bitCalc(param.re_ref_place, 1);
      this.reRefPlace2 = this._bitCalc(param.re_ref_place, 2);
      this.reRefPlace3 = this._bitCalc(param.re_ref_place, 3);
      this.reRefPlace4 = this._bitCalc(param.re_ref_place, 4);

      // 登録情報
      this.reEntryDay     = param.re_entry_d;
      this.reEntryTime    = param.re_entry_t;
      this.reEntryEndDay  = param.re_entry_end_d;
      this.reEntryEndTime = param.re_entry_end_t;
      this.reEntryMsg     = param.re_entry_msg;
      this.reEntryOpt     = param.re_entry_opt;

      this.reCondLim    = param.re_cond_lim;
      this.reCondWait   = param.re_cond_wait;
      this.reEntryScorp = param.re_entry_scorp;

      this.reCondSex = param.re_cond_sex;
      this.reCondAge = param.re_cond_age;
      this.reCondCls = param.re_cond_cls;
      this.editDateTime = param.edit_dt;
      this.breadItems.push({ text: this.reName + '編集', active: true});
      this.isLoading = false;
    }).catch((e: Error) => {
      window.console.log(e);
    });
  }

  private save(): void {
    // Fromチェック
    let tmpReFromType;
    let tmpReFromId = 1;
    if (this.reFromId === 0) {
      tmpReFromType = 1;
      tmpReFromId = 1;
    } else {
      tmpReFromType = 2;
      tmpReFromId = this.reFromId;
    }
    // statusチェック
    let tmpReStatus: number;
    if (this.reStatusFlg) {
      tmpReStatus = this.C_COMMON_VALID;
    } else {
      if (this.reStatus < this.C_COMMON_VALID) {
        tmpReStatus = this.C_COMMON_DRAFT;
      } else {
        tmpReStatus = this.C_COMMON_CLOSE;
      }
    }

    const postParam: {[key: string]: any} = {
      re_type:        this.reType,
      re_status:      tmpReStatus,
      re_mode:        0,
      re_name:        this.reName,
      re_to_type:     0,
      re_to_o_id:     0,
      re_to_d_id:     0,
      re_to_p_id:     0,
      re_from_type:   tmpReFromType,
      re_from_id:     tmpReFromId,
      re_from_name:   this.reFromName,
      re_from_url:    this.reFromUrl,
      re_cycle:       this.reCycle,
      re_tgt_day:     this.reTgtDay,
      re_tgt_week:    this.reTgtWeek,
      re_tgt_date:    this.reTgtDate,
      re_first_t:     this.reFirstTime,
      re_duration:    this.reDuration,
      re_open_d:      this.reOpenDay,
      re_open_t:      this.reOpenTime,
      re_close_d:     this.reCloseDay,
      re_close_t:     this.reCloseTime,
      re_entry_d:     0,
      re_entry_t:     '',
      re_entry_end_d: 0,
      re_entry_end_t: '',
      re_entry_msg:   '',
      re_entry_opt:   '',
      re_entry_scorp: 0,
      re_cond_cls:    '',
      re_cond_sex:    0,
      re_cond_age:    0,
      re_cond_lim:    0,
      re_cond_wait:   0,
      re_category:    0,
      re_times:       this.reTimes,
      re_title:       this.reTitle,
      re_summary:     this.reSummary,
      re_body:        this.reBody,
      re_img_path:    this.reImgPath,
      re_img_text:    this.reImgText,
      re_hp:          this.reHp,
      re_fb:          this.reFb,
      re_configs:     '',
      re_ref_place:   0,
      re_list_scorp:  this.reListScorp,
      re_list_lead:   this.reListLead,
    };

    let tmpReMode = 0;

    if (this.reMode1) {
      tmpReMode += 1;
      postParam.re_category = this.reCategory;
    }

    if (this.reMode2) {
      tmpReMode += 2;
      let tmpReToType = 0;
      tmpReToType += (this.reToType1) ? 1 : 0;
      tmpReToType += (this.reToType2) ? 2 : 0;
      tmpReToType += (this.reToType3) ? 4 : 0;
      tmpReToType += (this.reToType4) ? 8 : 0;

      const target = this.reFromData.find((item) => {
        return (item.value === this.reFromId);
      });
      if (target) {
        if (this._bitCalc(target.target, 1) && target.ref_type === 1) {
          postParam.re_to_o_id = target.ref_id;
        }

        if (this._bitCalc(target.target, 2) && target.ref_type === 2) {
          postParam.re_to_d_id = target.ref_id;
        }

        if (this._bitCalc(target.target, 3)) {
          postParam.re_to_p_id = target.ref_pref;
        }
      } else {
        // あとで
      }

      postParam.re_to_type =     tmpReToType;
      postParam.re_entry_d =     this.reEntryDay;
      postParam.re_entry_t =     this.reEntryTime;
      postParam.re_entry_end_d = this.reEntryEndDay;
      postParam.re_entry_end_t = this.reEntryEndTime;
      postParam.re_entry_msg =   this.reEntryMsg;
      postParam.re_entry_opt =   this.reEntryOpt;
      postParam.re_entry_scorp = this.reEntryScorp;
      postParam.re_cond_cls =    this.reCondCls;
      postParam.re_cond_sex =    this.reCondSex;
      postParam.re_cond_age =    this.reCondAge;
      postParam.re_cond_lim =    this.reCondLim;
      postParam.re_cond_wait =   this.reCondWait;
    }

    if (this.reMode3) {
      tmpReMode += 4;
      let tmpReRefPlace = 0;

      tmpReRefPlace += (this.reRefPlace1) ? 1 : 0;
      tmpReRefPlace += (this.reRefPlace2) ? 2 : 0;
      tmpReRefPlace += (this.reRefPlace3) ? 4 : 0;
      tmpReRefPlace += (this.reRefPlace4) ? 8 : 0;

      postParam.re_configs   = this.reConfigs.join(',');
      postParam.re_ref_place = tmpReRefPlace;
    }

    postParam.re_mode = tmpReMode;

    // 新規か
    if (this.regularId === 0) {
      this.create(postParam);
    } else {
      postParam.edit_dt = this.editDateTime;
      this.update(postParam);
    }
  }

  private create(postParam: {[key: string]: any}) {
    const token = this.$store.getters.getToken();
    libAxios.post('regular-data', token, postParam)
    .then((response) => {
      window.console.log(123);
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }

  private update(postParam: {[key: string]: any}) {
    const token = this.$store.getters.getToken();
    libAxios.put('regular-data/' + this.regularId, token, postParam)
    .then((response) => {
      window.console.log(123);
    }).catch((e: Error) => {
      throw new Error(e.message);
    });
  }
}
