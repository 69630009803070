





























































import {Component, Vue} from 'vue-property-decorator';
import Header       from '@/components/common/Header.vue';
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

@Component({
  components: {
    Header,
  },
})
export default class Top extends Vue {
  private breadItems: Array<{[key: string]: any}> = [
    { text: 'Top', active: true},
  ];

  private created(): void {
    try {
      this._checkLogin().
      then(() => {
        this._getResumeData(0); // Gameではないので戻り値は無視
      }).catch((e: Error) => {
        throw new Error(e.message);
      });
    } catch (e) {
      this.$router.push({name: 'error'});
    }
  }
}
